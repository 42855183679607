.pf-c-expandable-section {
  --pf-c-expandable-section__toggle--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-expandable-section__toggle--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-expandable-section__toggle--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-expandable-section__toggle--PaddingLeft: 0;
  --pf-c-expandable-section__toggle--Color: var(--pf-global--link--Color);
  --pf-c-expandable-section__toggle--hover--Color: var(--pf-global--link--Color--hover);
  --pf-c-expandable-section__toggle--active--Color: var(--pf-global--link--Color--hover);
  --pf-c-expandable-section__toggle--focus--Color: var(--pf-global--link--Color--hover);
  --pf-c-expandable-section__toggle--m-expanded--Color: var(--pf-global--link--Color--hover);
  --pf-c-expandable-section__toggle-icon--Color: var(--pf-global--Color--100);
  --pf-c-expandable-section__toggle-icon--Transition: .2s ease-in 0s;
  --pf-c-expandable-section__toggle-icon--Rotate: 0;
  --pf-c-expandable-section--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-expandable-section__toggle-text--MarginLeft: calc(var(--pf-global--spacer--xs) + var(--pf-global--spacer--sm));
  --pf-c-expandable-section__content--MarginTop: var(--pf-global--spacer--md); }
  .pf-c-expandable-section.pf-m-expanded {
    --pf-c-expandable-section__toggle--Color: var(--pf-c-expandable-section__toggle--m-expanded--Color);
    --pf-c-expandable-section__toggle-icon--Rotate: var(--pf-c-expandable-section--m-expanded__toggle-icon--Rotate); }

.pf-c-expandable-section__toggle {
  display: flex;
  padding: var(--pf-c-expandable-section__toggle--PaddingTop) var(--pf-c-expandable-section__toggle--PaddingRight) var(--pf-c-expandable-section__toggle--PaddingBottom) var(--pf-c-expandable-section__toggle--PaddingLeft);
  color: var(--pf-c-expandable-section__toggle--Color);
  border: none; }
  .pf-c-expandable-section__toggle:hover {
    --pf-c-expandable-section__toggle--Color: var(--pf-c-expandable-section__toggle--hover--Color); }
  .pf-c-expandable-section__toggle:active, .pf-c-expandable-section__toggle.pf-m-active {
    --pf-c-expandable-section__toggle--Color: var(--pf-c-expandable-section__toggle--active--Color); }
  .pf-c-expandable-section__toggle:focus {
    --pf-c-expandable-section__toggle--Color: var(--pf-c-expandable-section__toggle--focus--Color); }

.pf-c-expandable-section__toggle-icon {
  color: var(--pf-c-expandable-section__toggle-icon--Color);
  transition: var(--pf-c-expandable-section__toggle-icon--Transition);
  transform: rotate(var(--pf-c-expandable-section__toggle-icon--Rotate)); }

.pf-c-expandable-section__toggle-text {
  margin-left: var(--pf-c-expandable-section__toggle-text--MarginLeft); }

.pf-c-expandable-section__content {
  margin-top: var(--pf-c-expandable-section__content--MarginTop); }

.pf-m-overpass-font .pf-c-expandable-section__toggle {
  font-weight: var(--pf-global--FontWeight--semi-bold); }
