.pf-c-tabs {
  --pf-c-tabs--inset: 0;
  --pf-c-tabs--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-tabs--before--border-width--base: var(--pf-global--BorderWidth--sm);
  --pf-c-tabs--before--BorderTopWidth: 0;
  --pf-c-tabs--before--BorderRightWidth: 0;
  --pf-c-tabs--before--BorderBottomWidth: var(--pf-c-tabs--before--border-width--base);
  --pf-c-tabs--before--BorderLeftWidth: 0;
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
  --pf-c-tabs--m-vertical--MaxWidth: 15.625rem;
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl);
  --pf-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth: var(--pf-c-tabs__link--before--border-width--base);
  --pf-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth: var(--pf-c-tabs--before--border-width--base);
  --pf-c-tabs--m-color-scheme--light-300__link--BackgroundColor: transparent;
  --pf-c-tabs--m-color-scheme--light-300__item--m-current__link--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-tabs__link--Color: var(--pf-global--Color--200);
  --pf-c-tabs__link--FontSize: var(--pf-global--FontSize--md);
  --pf-c-tabs__link--BackgroundColor: transparent;
  --pf-c-tabs__link--OutlineOffset: calc(-1 * 0.375rem);
  --pf-c-tabs__link--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-tabs__link--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-tabs__link--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-tabs__link--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-tabs__item--m-current__link--Color: var(--pf-global--Color--100);
  --pf-c-tabs__item--m-current__link--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-tabs--m-vertical__link--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-tabs--m-vertical__link--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-tabs--m-box__link--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-tabs--m-secondary__link--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-tabs__link--before--border-color--base: var(--pf-global--BorderColor--100);
  --pf-c-tabs__link--before--BorderRightColor: var(--pf-c-tabs__link--before--border-color--base);
  --pf-c-tabs__link--before--BorderBottomColor: var(--pf-c-tabs__link--before--border-color--base);
  --pf-c-tabs__link--before--border-width--base: var(--pf-global--BorderWidth--sm);
  --pf-c-tabs__link--before--BorderTopWidth: 0;
  --pf-c-tabs__link--before--BorderRightWidth: 0;
  --pf-c-tabs__link--before--BorderBottomWidth: 0;
  --pf-c-tabs__link--before--BorderLeftWidth: 0;
  --pf-c-tabs__link--before--Left: calc(var(--pf-c-tabs__link--before--border-width--base) * -1);
  --pf-c-tabs__link--after--Top: auto;
  --pf-c-tabs__link--after--Right: 0;
  --pf-c-tabs__link--after--Bottom: 0;
  --pf-c-tabs__link--after--BorderColor: var(--pf-global--BorderColor--light-100);
  --pf-c-tabs__link--after--BorderWidth: 0;
  --pf-c-tabs__link--after--BorderTopWidth: 0;
  --pf-c-tabs__link--after--BorderRightWidth: 0;
  --pf-c-tabs__link--after--BorderLeftWidth: 0;
  --pf-c-tabs__link--hover--after--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-tabs__link--focus--after--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-tabs__link--active--after--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-tabs__item--m-current__link--after--BorderColor: var(--pf-global--active-color--100);
  --pf-c-tabs__item--m-current__link--after--BorderWidth: var(--pf-global--BorderWidth--lg);
  --pf-c-tabs__link--child--MarginRight: var(--pf-global--spacer--md);
  --pf-c-tabs__scroll-button--Color: var(--pf-global--Color--100);
  --pf-c-tabs__scroll-button--hover--Color: var(--pf-global--active-color--100);
  --pf-c-tabs__scroll-button--disabled--Color: var(--pf-global--disabled-color--200);
  --pf-c-tabs__scroll-button--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-tabs__scroll-button--Width: var(--pf-global--spacer--2xl);
  --pf-c-tabs__scroll-button--xl--Width: var(--pf-global--spacer--3xl);
  --pf-c-tabs__scroll-button--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
  --pf-c-tabs__scroll-button--TransitionDuration--margin: .125s;
  --pf-c-tabs__scroll-button--TransitionDuration--transform: .125s;
  --pf-c-tabs__scroll-button--TransitionDuration--opacity: .125s;
  --pf-c-tabs__scroll-button--before--BorderColor: var(--pf-c-tabs--before--BorderColor);
  --pf-c-tabs__scroll-button--before--border-width--base: var(--pf-global--BorderWidth--sm);
  --pf-c-tabs__scroll-button--before--BorderRightWidth: 0;
  --pf-c-tabs__scroll-button--before--BorderBottomWidth: var(--pf-c-tabs__scroll-button--before--border-width--base);
  --pf-c-tabs__scroll-button--before--BorderLeftWidth: 0;
  position: relative;
  display: flex;
  padding-right: var(--pf-c-tabs--inset);
  padding-left: var(--pf-c-tabs--inset);
  overflow: hidden; }
  @media screen and (min-width: 1200px) {
    .pf-c-tabs {
      --pf-c-tabs__scroll-button--Width: var(--pf-c-tabs__scroll-button--xl--Width); } }
  .pf-c-tabs::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid var(--pf-c-tabs--before--BorderColor);
    border-width: var(--pf-c-tabs--before--BorderTopWidth) var(--pf-c-tabs--before--BorderRightWidth) var(--pf-c-tabs--before--BorderBottomWidth) var(--pf-c-tabs--before--BorderLeftWidth); }
  .pf-c-tabs.pf-m-fill .pf-c-tabs__list {
    flex-basis: 100%; }
  .pf-c-tabs.pf-m-fill .pf-c-tabs__item {
    flex-grow: 1; }
    .pf-c-tabs.pf-m-fill .pf-c-tabs__item:first-child {
      --pf-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth: 0; }
    .pf-c-tabs.pf-m-fill .pf-c-tabs__item:last-child {
      --pf-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth: 0; }
  .pf-c-tabs.pf-m-fill .pf-c-tabs__link {
    flex-basis: 100%;
    justify-content: center; }
  .pf-c-tabs.pf-m-scrollable .pf-c-tabs__scroll-button {
    opacity: 1; }
  .pf-c-tabs.pf-m-scrollable .pf-c-tabs__scroll-button:nth-of-type(1) {
    margin-right: 0;
    transform: translateX(0); }
  .pf-c-tabs.pf-m-scrollable .pf-c-tabs__scroll-button:nth-of-type(2) {
    margin-left: 0;
    transform: translateX(0); }
  .pf-c-tabs.pf-m-secondary, .pf-c-tabs.pf-m-no-border-bottom {
    --pf-c-tabs--before--BorderBottomWidth: 0; }
  .pf-c-tabs.pf-m-box .pf-c-tabs__link, .pf-c-tabs.pf-m-vertical .pf-c-tabs__link {
    --pf-c-tabs__link--after--BorderBottomWidth: 0; }
  .pf-c-tabs.pf-m-box {
    --pf-c-tabs__link--BackgroundColor: var(--pf-c-tabs--m-box__link--BackgroundColor);
    --pf-c-tabs__link--before--BorderBottomWidth: var(--pf-c-tabs__link--before--border-width--base);
    --pf-c-tabs__link--before--BorderRightWidth: var(--pf-c-tabs__link--before--border-width--base);
    --pf-c-tabs__link--after--Top: 0;
    --pf-c-tabs__link--after--Bottom: auto; }
    .pf-c-tabs.pf-m-box .pf-c-tabs__link {
      --pf-c-tabs__link--after--BorderTopWidth: var(--pf-c-tabs__link--after--BorderWidth); }
    .pf-c-tabs.pf-m-box .pf-c-tabs__item:last-child {
      --pf-c-tabs__link--before--BorderRightWidth: 0; }
    .pf-c-tabs.pf-m-box .pf-c-tabs__item.pf-m-current {
      --pf-c-tabs__link--BackgroundColor: var(--pf-c-tabs__item--m-current__link--BackgroundColor);
      --pf-c-tabs__link--before--BorderBottomColor: var(--pf-c-tabs__link--BackgroundColor); }
    .pf-c-tabs.pf-m-box .pf-c-tabs__item.pf-m-current:first-child .pf-c-tabs__link::before {
      border-left-width: var(--pf-c-tabs--m-box__item--m-current--first-child__link--before--BorderLeftWidth); }
    .pf-c-tabs.pf-m-box .pf-c-tabs__item.pf-m-current:last-child .pf-c-tabs__link::before {
      border-right-width: var(--pf-c-tabs--m-box__item--m-current--last-child__link--before--BorderRightWidth); }
    .pf-c-tabs.pf-m-box.pf-m-scrollable .pf-c-tabs__item.pf-m-current:first-child .pf-c-tabs__link::before {
      left: calc(var(--pf-c-tabs__link--before--border-width--base) * -1); }
    .pf-c-tabs.pf-m-box.pf-m-scrollable .pf-c-tabs__scroll-button:nth-of-type(2)::before {
      left: calc(var(--pf-c-tabs__link--before--border-width--base) * -1); }
    .pf-c-tabs.pf-m-box .pf-c-tabs__item.pf-m-current + .pf-c-tabs__item {
      --pf-c-tabs__link--before--Left: 0; }
    .pf-c-tabs.pf-m-box.pf-m-color-scheme--light-300 {
      --pf-c-tabs__link--BackgroundColor: var(--pf-c-tabs--m-color-scheme--light-300__link--BackgroundColor);
      --pf-c-tabs__item--m-current__link--BackgroundColor: var(--pf-c-tabs--m-color-scheme--light-300__item--m-current__link--BackgroundColor); }
  .pf-c-tabs.pf-m-vertical {
    --pf-c-tabs--inset: var(--pf-c-tabs--m-vertical--inset);
    --pf-c-tabs--before--BorderBottomWidth: 0;
    --pf-c-tabs--before--BorderLeftWidth: var(--pf-c-tabs--before--border-width--base);
    --pf-c-tabs__link--PaddingTop: var(--pf-c-tabs--m-vertical__link--PaddingTop);
    --pf-c-tabs__link--PaddingBottom: var(--pf-c-tabs--m-vertical__link--PaddingBottom);
    --pf-c-tabs__link--before--Left: 0;
    --pf-c-tabs__link--after--Top: 0;
    --pf-c-tabs__link--after--Bottom: 0;
    --pf-c-tabs__link--after--Right: auto;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    padding: 0; }
    .pf-c-tabs.pf-m-vertical::before {
      top: 0;
      right: auto; }
    .pf-c-tabs.pf-m-vertical .pf-c-tabs__list {
      flex-direction: column;
      max-width: var(--pf-c-tabs--m-vertical--MaxWidth); }
    .pf-c-tabs.pf-m-vertical .pf-c-tabs__item:first-child {
      margin-top: var(--pf-c-tabs--inset); }
    .pf-c-tabs.pf-m-vertical .pf-c-tabs__item:last-child {
      margin-bottom: var(--pf-c-tabs--inset); }
    .pf-c-tabs.pf-m-vertical .pf-c-tabs__link {
      --pf-c-tabs__link--after--BorderTopWidth: 0;
      --pf-c-tabs__link--after--BorderLeftWidth: var(--pf-c-tabs__link--after--BorderWidth);
      max-width: 100%;
      text-align: left; }
    .pf-c-tabs.pf-m-vertical .pf-c-tabs__item-text {
      max-width: 100%;
      overflow-wrap: break-word; }
  .pf-c-tabs.pf-m-box.pf-m-vertical {
    --pf-c-tabs--inset: var(--pf-c-tabs--m-vertical--m-box--inset);
    --pf-c-tabs--before--BorderLeftWidth: 0;
    --pf-c-tabs--before--BorderRightWidth: var(--pf-c-tabs--before--border-width--base); }
    .pf-c-tabs.pf-m-box.pf-m-vertical::before {
      right: 0;
      left: auto; }
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item:last-child {
      --pf-c-tabs__link--before--BorderBottomWidth: 0;
      --pf-c-tabs__link--before--BorderRightWidth: var(--pf-c-tabs__link--before--border-width--base); }
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item.pf-m-current {
      --pf-c-tabs__link--before--BorderRightColor: var(--pf-c-tabs__item--m-current__link--BackgroundColor);
      --pf-c-tabs__link--before--BorderBottomColor: var(--pf-c-tabs__link--before--border-color--base);
      --pf-c-tabs__link--before--BorderBottomWidth: var(--pf-c-tabs__link--before--border-width--base); }
      .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item.pf-m-current:first-child {
        --pf-c-tabs__link--before--BorderTopWidth: var(--pf-c-tabs__link--before--border-width--base); }
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item:first-child.pf-m-current {
      --pf-c-tabs__link--before--BorderTopWidth: var(--pf-c-tabs__link--before--border-width--base); }
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__link::after {
      top: calc(var(--pf-c-tabs__link--before--border-width--base) * -1); }
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item:first-child .pf-c-tabs__link::after,
    .pf-c-tabs.pf-m-box.pf-m-vertical .pf-c-tabs__item.pf-m-current + .pf-c-tabs__item .pf-c-tabs__link::after {
      top: 0; }
  .pf-c-tabs.pf-m-secondary {
    --pf-c-tabs__link--FontSize: var(--pf-c-tabs--m-secondary__link--FontSize); }

.pf-c-tabs__list {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }
  .pf-c-tabs__list::-webkit-scrollbar {
    display: none; }

.pf-c-tabs__item {
  display: flex;
  flex: none; }
  .pf-c-tabs__item.pf-m-current {
    --pf-c-tabs__link--Color: var(--pf-c-tabs__item--m-current__link--Color);
    --pf-c-tabs__link--after--BorderColor: var(--pf-c-tabs__item--m-current__link--after--BorderColor);
    --pf-c-tabs__link--after--BorderWidth: var(--pf-c-tabs__item--m-current__link--after--BorderWidth); }

.pf-c-tabs__link,
.pf-c-tabs__scroll-button {
  border: 0; }

.pf-c-tabs::before,
.pf-c-tabs__link::before,
.pf-c-tabs__link::after,
.pf-c-tabs__scroll-button::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-style: solid; }

.pf-c-tabs__link::before,
.pf-c-tabs__link::after,
.pf-c-tabs__scroll-button::before {
  top: 0; }

.pf-c-tabs__link {
  --pf-c-tabs__link--after--BorderBottomWidth: var(--pf-c-tabs__link--after--BorderWidth);
  position: relative;
  display: flex;
  flex: 1;
  padding: var(--pf-c-tabs__link--PaddingTop) var(--pf-c-tabs__link--PaddingRight) var(--pf-c-tabs__link--PaddingBottom) var(--pf-c-tabs__link--PaddingLeft);
  font-size: var(--pf-c-tabs__link--FontSize);
  color: var(--pf-c-tabs__link--Color);
  text-decoration: none;
  background-color: var(--pf-c-tabs__link--BackgroundColor);
  outline-offset: var(--pf-c-tabs__link--OutlineOffset); }
  .pf-c-tabs__link::before {
    pointer-events: none;
    border-color: var(--pf-c-tabs__link--before--border-color--base);
    border-width: var(--pf-c-tabs__link--before--BorderTopWidth) var(--pf-c-tabs__link--before--BorderRightWidth) var(--pf-c-tabs__link--before--BorderBottomWidth) var(--pf-c-tabs__link--before--BorderLeftWidth);
    border-right-color: var(--pf-c-tabs__link--before--BorderRightColor);
    border-bottom-color: var(--pf-c-tabs__link--before--BorderBottomColor); }
  .pf-c-tabs__link::after {
    top: var(--pf-c-tabs__link--after--Top);
    right: var(--pf-c-tabs__link--after--Right);
    bottom: var(--pf-c-tabs__link--after--Bottom);
    left: var(--pf-c-tabs__link--before--Left);
    border-color: var(--pf-c-tabs__link--after--BorderColor);
    border-width: var(--pf-c-tabs__link--after--BorderTopWidth) var(--pf-c-tabs__link--after--BorderRightWidth) var(--pf-c-tabs__link--after--BorderBottomWidth) var(--pf-c-tabs__link--after--BorderLeftWidth); }
  .pf-c-tabs__link:hover {
    --pf-c-tabs__link--after--BorderWidth: var(--pf-c-tabs__link--hover--after--BorderWidth); }
  .pf-c-tabs__link:focus {
    --pf-c-tabs__link--after--BorderWidth: var(--pf-c-tabs__link--focus--after--BorderWidth); }
  .pf-c-tabs__link:active {
    --pf-c-tabs__link--after--BorderWidth: var(--pf-c-tabs__link--active--after--BorderWidth); }
  .pf-c-tabs__link .pf-c-tabs__item-icon,
  .pf-c-tabs__link .pf-c-tabs__item-text {
    margin-right: var(--pf-c-tabs__link--child--MarginRight); }
    .pf-c-tabs__link .pf-c-tabs__item-icon:last-child,
    .pf-c-tabs__link .pf-c-tabs__item-text:last-child {
      --pf-c-tabs__link--child--MarginRight: 0; }

.pf-c-tabs__scroll-button {
  flex: none;
  width: var(--pf-c-tabs__scroll-button--Width);
  line-height: 1;
  color: var(--pf-c-tabs__scroll-button--Color);
  background-color: var(--pf-c-tabs__scroll-button--BackgroundColor);
  outline-offset: var(--pf-c-tabs__scroll-button--OutlineOffset);
  opacity: 0;
  transition: margin var(--pf-c-tabs__scroll-button--TransitionDuration--margin), transform var(--pf-c-tabs__scroll-button--TransitionDuration--transform), opacity var(--pf-c-tabs__scroll-button--TransitionDuration--opacity); }
  .pf-c-tabs__scroll-button:hover, .pf-c-tabs__scroll-button:active, .pf-c-tabs__scroll-button:focus {
    --pf-c-tabs__scroll-button--Color: var(--pf-c-tabs__scroll-button--hover--Color); }
  .pf-c-tabs__scroll-button::before {
    border-color: var(--pf-c-tabs__scroll-button--before--BorderColor);
    border-width: 0 var(--pf-c-tabs__scroll-button--before--BorderRightWidth) var(--pf-c-tabs__scroll-button--before--BorderBottomWidth) var(--pf-c-tabs__scroll-button--before--BorderLeftWidth); }
  .pf-c-tabs__scroll-button:nth-of-type(1) {
    --pf-c-tabs__scroll-button--before--BorderRightWidth: var(--pf-c-tabs__scroll-button--before--border-width--base);
    margin-right: calc(var(--pf-c-tabs__scroll-button--Width) * -1);
    transform: translateX(-100%); }
  .pf-c-tabs__scroll-button:nth-of-type(2) {
    --pf-c-tabs__scroll-button--before--BorderLeftWidth: var(--pf-c-tabs__scroll-button--before--border-width--base);
    margin-left: calc(var(--pf-c-tabs__scroll-button--Width) * -1);
    transform: translateX(100%); }
  .pf-c-tabs__scroll-button:disabled {
    --pf-c-tabs__scroll-button--Color: var(--pf-c-tabs__scroll-button--disabled--Color);
    pointer-events: none; }

.pf-c-tabs.pf-m-inset-none {
  --pf-c-tabs--inset: 0;
  --pf-c-tabs--m-vertical--inset: 0;
  --pf-c-tabs--m-vertical--m-box--inset: 0; }

.pf-c-tabs.pf-m-inset-sm {
  --pf-c-tabs--inset: var(--pf-global--spacer--sm);
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }

.pf-c-tabs.pf-m-inset-md {
  --pf-c-tabs--inset: var(--pf-global--spacer--md);
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }

.pf-c-tabs.pf-m-inset-lg {
  --pf-c-tabs--inset: var(--pf-global--spacer--lg);
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }

.pf-c-tabs.pf-m-inset-xl {
  --pf-c-tabs--inset: var(--pf-global--spacer--xl);
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }

.pf-c-tabs.pf-m-inset-2xl {
  --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
  --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
  --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); }

@media (min-width: 576px) {
  .pf-c-tabs.pf-m-inset-none-on-sm {
    --pf-c-tabs--inset: 0;
    --pf-c-tabs--m-vertical--inset: 0;
    --pf-c-tabs--m-vertical--m-box--inset: 0; }
  .pf-c-tabs.pf-m-inset-sm-on-sm {
    --pf-c-tabs--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }
  .pf-c-tabs.pf-m-inset-md-on-sm {
    --pf-c-tabs--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }
  .pf-c-tabs.pf-m-inset-lg-on-sm {
    --pf-c-tabs--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }
  .pf-c-tabs.pf-m-inset-xl-on-sm {
    --pf-c-tabs--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }
  .pf-c-tabs.pf-m-inset-2xl-on-sm {
    --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); } }

@media (min-width: 768px) {
  .pf-c-tabs.pf-m-inset-none-on-md {
    --pf-c-tabs--inset: 0;
    --pf-c-tabs--m-vertical--inset: 0;
    --pf-c-tabs--m-vertical--m-box--inset: 0; }
  .pf-c-tabs.pf-m-inset-sm-on-md {
    --pf-c-tabs--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }
  .pf-c-tabs.pf-m-inset-md-on-md {
    --pf-c-tabs--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }
  .pf-c-tabs.pf-m-inset-lg-on-md {
    --pf-c-tabs--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }
  .pf-c-tabs.pf-m-inset-xl-on-md {
    --pf-c-tabs--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }
  .pf-c-tabs.pf-m-inset-2xl-on-md {
    --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); } }

@media (min-width: 992px) {
  .pf-c-tabs.pf-m-inset-none-on-lg {
    --pf-c-tabs--inset: 0;
    --pf-c-tabs--m-vertical--inset: 0;
    --pf-c-tabs--m-vertical--m-box--inset: 0; }
  .pf-c-tabs.pf-m-inset-sm-on-lg {
    --pf-c-tabs--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }
  .pf-c-tabs.pf-m-inset-md-on-lg {
    --pf-c-tabs--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }
  .pf-c-tabs.pf-m-inset-lg-on-lg {
    --pf-c-tabs--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }
  .pf-c-tabs.pf-m-inset-xl-on-lg {
    --pf-c-tabs--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }
  .pf-c-tabs.pf-m-inset-2xl-on-lg {
    --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); } }

@media (min-width: 1200px) {
  .pf-c-tabs.pf-m-inset-none-on-xl {
    --pf-c-tabs--inset: 0;
    --pf-c-tabs--m-vertical--inset: 0;
    --pf-c-tabs--m-vertical--m-box--inset: 0; }
  .pf-c-tabs.pf-m-inset-sm-on-xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }
  .pf-c-tabs.pf-m-inset-md-on-xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }
  .pf-c-tabs.pf-m-inset-lg-on-xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }
  .pf-c-tabs.pf-m-inset-xl-on-xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }
  .pf-c-tabs.pf-m-inset-2xl-on-xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); } }

@media (min-width: 1450px) {
  .pf-c-tabs.pf-m-inset-none-on-2xl {
    --pf-c-tabs--inset: 0;
    --pf-c-tabs--m-vertical--inset: 0;
    --pf-c-tabs--m-vertical--m-box--inset: 0; }
  .pf-c-tabs.pf-m-inset-sm-on-2xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--sm);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--sm); }
  .pf-c-tabs.pf-m-inset-md-on-2xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--md);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--md); }
  .pf-c-tabs.pf-m-inset-lg-on-2xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--lg);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--lg); }
  .pf-c-tabs.pf-m-inset-xl-on-2xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--xl); }
  .pf-c-tabs.pf-m-inset-2xl-on-2xl {
    --pf-c-tabs--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--inset: var(--pf-global--spacer--2xl);
    --pf-c-tabs--m-vertical--m-box--inset: var(--pf-global--spacer--2xl); } }
