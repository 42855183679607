.pf-c-breadcrumb {
  --pf-c-breadcrumb__item--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-breadcrumb__item--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-breadcrumb__item--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-breadcrumb__item-divider--Color: var(--pf-global--BorderColor--200);
  --pf-c-breadcrumb__item-divider--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-breadcrumb__item-divider--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-breadcrumb__link--m-current--Color: var(--pf-global--Color--100);
  --pf-c-breadcrumb__heading--FontSize: var(--pf-global--FontSize--sm);
  display: inline-flex; }

.pf-c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.pf-c-breadcrumb__item {
  display: flex;
  align-items: baseline;
  font-size: var(--pf-c-breadcrumb__item--FontSize);
  font-weight: var(--pf-c-breadcrumb__item--FontWeight);
  line-height: var(--pf-c-breadcrumb__item--LineHeight);
  white-space: nowrap;
  list-style: none; }
  .pf-c-breadcrumb__item:not(:last-child) {
    margin-right: var(--pf-c-breadcrumb__item--MarginRight); }

.pf-c-breadcrumb__item-divider {
  margin-right: var(--pf-c-breadcrumb__item-divider--MarginRight);
  font-size: var(--pf-c-breadcrumb__item-divider--FontSize);
  line-height: 1;
  color: var(--pf-c-breadcrumb__item-divider--Color); }

.pf-c-breadcrumb__link {
  font-size: inherit;
  font-weight: var(--pf-c-breadcrumb__link--FontWeight);
  line-height: inherit;
  word-break: break-word; }
  .pf-c-breadcrumb__link.pf-m-current {
    cursor: default; }
    .pf-c-breadcrumb__link.pf-m-current, .pf-c-breadcrumb__link.pf-m-current:hover {
      color: var(--pf-c-breadcrumb__link--m-current--Color);
      text-decoration: none; }

.pf-c-breadcrumb__heading {
  display: inline;
  font-size: var(--pf-c-breadcrumb__heading--FontSize); }

.pf-c-breadcrumb__link,
.pf-c-breadcrumb__heading {
  white-space: normal; }

.pf-m-overpass-font .pf-c-breadcrumb__link,
.pf-m-overpass-font .pf-c-breadcrumb__item {
  font-weight: var(--pf-global--FontWeight--semi-bold); }

.pf-c-breadcrumb__list > :first-child .pf-c-breadcrumb__item-divider {
  display: none;
  visibility: hidden; }
