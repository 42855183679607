.pf-c-button {
  --pf-c-button--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-button--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-button--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-button--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-button--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-button--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-button--FontSize: var(--pf-global--FontSize--md);
  --pf-c-button--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-button--after--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-button--after--BorderColor: transparent;
  --pf-c-button--after--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-button--hover--after--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--focus--after--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--active--after--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-button--disabled--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-button--disabled--after--BorderColor: transparent;
  --pf-c-button--m-primary--BackgroundColor: var(--pf-global--primary-color--100);
  --pf-c-button--m-primary--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-primary--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-primary--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-primary--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--BackgroundColor: transparent;
  --pf-c-button--m-secondary--after--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--hover--BackgroundColor: transparent;
  --pf-c-button--m-secondary--hover--after--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--hover--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--focus--BackgroundColor: transparent;
  --pf-c-button--m-secondary--focus--after--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--focus--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--active--BackgroundColor: transparent;
  --pf-c-button--m-secondary--active--after--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--active--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-tertiary--BackgroundColor: transparent;
  --pf-c-button--m-tertiary--after--BorderColor: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--Color: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--hover--BackgroundColor: transparent;
  --pf-c-button--m-tertiary--hover--after--BorderColor: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--hover--Color: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--focus--BackgroundColor: transparent;
  --pf-c-button--m-tertiary--focus--after--BorderColor: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--focus--Color: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--active--BackgroundColor: transparent;
  --pf-c-button--m-tertiary--active--after--BorderColor: var(--pf-global--Color--100);
  --pf-c-button--m-tertiary--active--Color: var(--pf-global--Color--100);
  --pf-c-button--m-warning--BackgroundColor: var(--pf-global--warning-color--100);
  --pf-c-button--m-warning--Color: var(--pf-global--Color--dark-100);
  --pf-c-button--m-warning--hover--BackgroundColor: var(--pf-global--palette--gold-500);
  --pf-c-button--m-warning--hover--Color: var(--pf-global--Color--dark-100);
  --pf-c-button--m-warning--focus--BackgroundColor: var(--pf-global--palette--gold-500);
  --pf-c-button--m-warning--focus--Color: var(--pf-global--Color--dark-100);
  --pf-c-button--m-warning--active--BackgroundColor: var(--pf-global--palette--gold-500);
  --pf-c-button--m-warning--active--Color: var(--pf-global--Color--dark-100);
  --pf-c-button--m-danger--BackgroundColor: var(--pf-global--danger-color--100);
  --pf-c-button--m-danger--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-danger--hover--BackgroundColor: var(--pf-global--danger-color--200);
  --pf-c-button--m-danger--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-danger--focus--BackgroundColor: var(--pf-global--danger-color--200);
  --pf-c-button--m-danger--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-danger--active--BackgroundColor: var(--pf-global--danger-color--200);
  --pf-c-button--m-danger--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-link--BackgroundColor: transparent;
  --pf-c-button--m-link--Color: var(--pf-global--link--Color);
  --pf-c-button--m-link--hover--BackgroundColor: transparent;
  --pf-c-button--m-link--hover--Color: var(--pf-global--link--Color--hover);
  --pf-c-button--m-link--focus--BackgroundColor: transparent;
  --pf-c-button--m-link--focus--Color: var(--pf-global--link--Color--hover);
  --pf-c-button--m-link--active--BackgroundColor: transparent;
  --pf-c-button--m-link--active--Color: var(--pf-global--link--Color--hover);
  --pf-c-button--m-link--disabled--BackgroundColor: transparent;
  --pf-c-button--m-link--m-inline--FontSize: inherit;
  --pf-c-button--m-link--m-inline--hover--TextDecoration: var(--pf-global--link--TextDecoration--hover);
  --pf-c-button--m-link--m-inline--hover--Color: var(--pf-global--link--Color--hover);
  --pf-c-button--m-plain--BackgroundColor: transparent;
  --pf-c-button--m-plain--Color: var(--pf-global--Color--200);
  --pf-c-button--m-plain--hover--BackgroundColor: transparent;
  --pf-c-button--m-plain--hover--Color: var(--pf-global--Color--100);
  --pf-c-button--m-plain--focus--BackgroundColor: transparent;
  --pf-c-button--m-plain--focus--Color: var(--pf-global--Color--100);
  --pf-c-button--m-plain--active--BackgroundColor: transparent;
  --pf-c-button--m-plain--active--Color: var(--pf-global--Color--100);
  --pf-c-button--m-plain--disabled--Color: var(--pf-global--disabled-color--200);
  --pf-c-button--m-plain--disabled--BackgroundColor: transparent;
  --pf-c-button--m-control--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-button--m-control--Color: var(--pf-global--Color--100);
  --pf-c-button--m-control--BorderRadius: 0;
  --pf-c-button--m-control--after--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-button--m-control--after--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-button--m-control--after--BorderRightColor: var(--pf-global--BorderColor--300);
  --pf-c-button--m-control--after--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-button--m-control--after--BorderLeftColor: var(--pf-global--BorderColor--300);
  --pf-c-button--m-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-button--m-control--hover--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-button--m-control--hover--Color: var(--pf-global--Color--100);
  --pf-c-button--m-control--hover--after--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--m-control--hover--after--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-button--m-control--active--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-button--m-control--active--Color: var(--pf-global--Color--100);
  --pf-c-button--m-control--active--after--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--m-control--active--after--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-button--m-control--focus--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-button--m-control--focus--Color: var(--pf-global--Color--100);
  --pf-c-button--m-control--focus--after--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--m-control--focus--after--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-button--m-control--m-expanded--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-button--m-control--m-expanded--Color: var(--pf-global--Color--100);
  --pf-c-button--m-control--m-expanded--after--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-button--m-control--m-expanded--after--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-button--m-small--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-button--m-display-lg--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-button--m-display-lg--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-button--m-display-lg--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-button--m-display-lg--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-button--m-display-lg--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-button--m-link--m-display-lg--FontSize: var(--pf-global--FontSize--lg);
  --pf-c-button__icon--m-start--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-button__icon--m-end--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-button__progress--width: calc(var(--pf-global--icon--FontSize--md) + var(--pf-global--spacer--sm));
  --pf-c-button__progress--Opacity: 0;
  --pf-c-button__progress--TranslateY: -50%;
  --pf-c-button__progress--Top: 50%;
  --pf-c-button__progress--Left: var(--pf-global--spacer--md);
  --pf-c-button--m-progress--TransitionProperty: padding;
  --pf-c-button--m-progress--TransitionDuration: var(--pf-global--TransitionDuration);
  --pf-c-button--m-progress--PaddingRight: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2);
  --pf-c-button--m-progress--PaddingLeft: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width) / 2);
  --pf-c-button--m-in-progress--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-button--m-in-progress--PaddingLeft: calc(var(--pf-global--spacer--md) + var(--pf-c-button__progress--width));
  position: relative;
  display: inline-block;
  padding: var(--pf-c-button--PaddingTop) var(--pf-c-button--PaddingRight) var(--pf-c-button--PaddingBottom) var(--pf-c-button--PaddingLeft);
  font-size: var(--pf-c-button--FontSize);
  font-weight: var(--pf-c-button--FontWeight);
  line-height: var(--pf-c-button--LineHeight);
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: 0;
  border-radius: var(--pf-c-button--BorderRadius); }
  .pf-c-button::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: "";
    border: var(--pf-c-button--after--BorderWidth) solid;
    border-color: var(--pf-c-button--after--BorderColor);
    border-radius: var(--pf-c-button--after--BorderRadius); }
  .pf-c-button:hover {
    --pf-c-button--after--BorderWidth: var(--pf-c-button--hover--after--BorderWidth);
    text-decoration: none; }
  .pf-c-button:focus {
    --pf-c-button--after--BorderWidth: var(--pf-c-button--focus--after--BorderWidth); }
  .pf-c-button:active, .pf-c-button.pf-m-active {
    --pf-c-button--after--BorderWidth: var(--pf-c-button--active--after--BorderWidth); }
  .pf-c-button.pf-m-block {
    display: block;
    width: 100%; }
  .pf-c-button.pf-m-small {
    --pf-c-button--FontSize: var(--pf-c-button--m-small--FontSize); }
  .pf-c-button.pf-m-primary.pf-m-display-lg, .pf-c-button.pf-m-secondary.pf-m-display-lg, .pf-c-button.pf-m-tertiary.pf-m-display-lg, .pf-c-button.pf-m-link.pf-m-display-lg {
    --pf-c-button--PaddingTop: var(--pf-c-button--m-display-lg--PaddingTop);
    --pf-c-button--PaddingRight: var(--pf-c-button--m-display-lg--PaddingRight);
    --pf-c-button--PaddingBottom: var(--pf-c-button--m-display-lg--PaddingBottom);
    --pf-c-button--PaddingLeft: var(--pf-c-button--m-display-lg--PaddingLeft);
    --pf-c-button--FontWeight: var(--pf-c-button--m-display-lg--FontWeight); }
  .pf-c-button.pf-m-primary {
    color: var(--pf-c-button--m-primary--Color);
    background-color: var(--pf-c-button--m-primary--BackgroundColor); }
    .pf-c-button.pf-m-primary:hover {
      --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--hover--Color);
      --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--hover--BackgroundColor); }
    .pf-c-button.pf-m-primary:focus {
      --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--focus--Color);
      --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--focus--BackgroundColor); }
    .pf-c-button.pf-m-primary:active, .pf-c-button.pf-m-primary.pf-m-active {
      --pf-c-button--m-primary--Color: var(--pf-c-button--m-primary--active--Color);
      --pf-c-button--m-primary--BackgroundColor: var(--pf-c-button--m-primary--active--BackgroundColor); }
  .pf-c-button.pf-m-secondary {
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--after--BorderColor);
    color: var(--pf-c-button--m-secondary--Color);
    background-color: var(--pf-c-button--m-secondary--BackgroundColor); }
    .pf-c-button.pf-m-secondary:hover {
      --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--hover--Color);
      --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--hover--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--hover--after--BorderColor); }
    .pf-c-button.pf-m-secondary:focus {
      --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--focus--Color);
      --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--focus--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--focus--after--BorderColor); }
    .pf-c-button.pf-m-secondary.pf-m-active, .pf-c-button.pf-m-secondary:active {
      --pf-c-button--m-secondary--Color: var(--pf-c-button--m-secondary--active--Color);
      --pf-c-button--m-secondary--BackgroundColor: var(--pf-c-button--m-secondary--active--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-secondary--active--after--BorderColor); }
  .pf-c-button.pf-m-tertiary {
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--after--BorderColor);
    color: var(--pf-c-button--m-tertiary--Color);
    background-color: var(--pf-c-button--m-tertiary--BackgroundColor); }
    .pf-c-button.pf-m-tertiary:hover {
      --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--hover--Color);
      --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--hover--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--hover--after--BorderColor); }
    .pf-c-button.pf-m-tertiary:focus {
      --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--focus--Color);
      --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--focus--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--focus--after--BorderColor); }
    .pf-c-button.pf-m-tertiary:active, .pf-c-button.pf-m-tertiary.pf-m-active {
      --pf-c-button--m-tertiary--Color: var(--pf-c-button--m-tertiary--active--Color);
      --pf-c-button--m-tertiary--BackgroundColor: var(--pf-c-button--m-tertiary--active--BackgroundColor);
      --pf-c-button--after--BorderColor: var(--pf-c-button--m-tertiary--active--after--BorderColor); }
  .pf-c-button.pf-m-danger {
    color: var(--pf-c-button--m-danger--Color);
    background-color: var(--pf-c-button--m-danger--BackgroundColor); }
    .pf-c-button.pf-m-danger:hover {
      --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--hover--Color);
      --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--hover--BackgroundColor); }
    .pf-c-button.pf-m-danger:focus {
      --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--focus--Color);
      --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--focus--BackgroundColor); }
    .pf-c-button.pf-m-danger:active, .pf-c-button.pf-m-danger.pf-m-active {
      --pf-c-button--m-danger--Color: var(--pf-c-button--m-danger--active--Color);
      --pf-c-button--m-danger--BackgroundColor: var(--pf-c-button--m-danger--active--BackgroundColor); }
  .pf-c-button.pf-m-warning {
    color: var(--pf-c-button--m-warning--Color);
    background-color: var(--pf-c-button--m-warning--BackgroundColor); }
    .pf-c-button.pf-m-warning:hover {
      --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--hover--Color);
      --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--hover--BackgroundColor); }
    .pf-c-button.pf-m-warning:focus {
      --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--focus--Color);
      --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--focus--BackgroundColor); }
    .pf-c-button.pf-m-warning:active, .pf-c-button.pf-m-warning.pf-m-active {
      --pf-c-button--m-warning--Color: var(--pf-c-button--m-warning--active--Color);
      --pf-c-button--m-warning--BackgroundColor: var(--pf-c-button--m-warning--active--BackgroundColor); }
  .pf-c-button.pf-m-link {
    --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-link--disabled--BackgroundColor);
    color: var(--pf-c-button--m-link--Color);
    background-color: var(--pf-c-button--m-link--BackgroundColor); }
    .pf-c-button.pf-m-link:not(.pf-m-inline):hover {
      --pf-c-button--m-link--Color: var(--pf-c-button--m-link--hover--Color);
      --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--hover--BackgroundColor); }
    .pf-c-button.pf-m-link:not(.pf-m-inline):focus {
      --pf-c-button--m-link--Color: var(--pf-c-button--m-link--focus--Color);
      --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--focus--BackgroundColor); }
    .pf-c-button.pf-m-link:not(.pf-m-inline):active, .pf-c-button.pf-m-link:not(.pf-m-inline).pf-m-active {
      --pf-c-button--m-link--Color: var(--pf-c-button--m-link--active--Color);
      --pf-c-button--m-link--BackgroundColor: var(--pf-c-button--m-link--active--BackgroundColor); }
    .pf-c-button.pf-m-link.pf-m-inline {
      --pf-c-button--FontSize: var(--pf-c-button--m-link--m-inline--FontSize);
      display: inline;
      padding: 0;
      text-align: left;
      white-space: normal;
      cursor: pointer; }
      .pf-c-button.pf-m-link.pf-m-inline:hover {
        --pf-c-button--m-link--Color: var(--pf-c-button--m-link--m-inline--hover--Color);
        text-decoration: var(--pf-c-button--m-link--m-inline--hover--TextDecoration); }
    .pf-c-button.pf-m-link.pf-m-display-lg {
      --pf-c-button--FontSize: var(--pf-c-button--m-link--m-display-lg--FontSize); }
  .pf-c-button.pf-m-control {
    --pf-c-button--BorderRadius: var(--pf-c-button--m-control--BorderRadius);
    --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-control--disabled--BackgroundColor);
    --pf-c-button--after--BorderWidth: var(--pf-c-button--m-control--after--BorderWidth);
    --pf-c-button--after--BorderColor: var(--pf-c-button--m-control--after--BorderTopColor) var(--pf-c-button--m-control--after--BorderRightColor) var(--pf-c-button--m-control--after--BorderBottomColor) var(--pf-c-button--m-control--after--BorderLeftColor);
    color: var(--pf-c-button--m-control--Color);
    background-color: var(--pf-c-button--m-control--BackgroundColor); }
    .pf-c-button.pf-m-control::after {
      border-radius: initial; }
    .pf-c-button.pf-m-control:hover {
      --pf-c-button--m-control--Color: var(--pf-c-button--m-control--hover--Color);
      --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--hover--BackgroundColor);
      --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--hover--after--BorderBottomColor); }
      .pf-c-button.pf-m-control:hover::after {
        border-bottom-width: var(--pf-c-button--m-control--hover--after--BorderBottomWidth); }
    .pf-c-button.pf-m-control:active, .pf-c-button.pf-m-control.pf-m-active {
      --pf-c-button--m-control--Color: var(--pf-c-button--m-control--active--Color);
      --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--active--BackgroundColor);
      --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--active--after--BorderBottomColor); }
      .pf-c-button.pf-m-control:active::after, .pf-c-button.pf-m-control.pf-m-active::after {
        border-bottom-width: var(--pf-c-button--m-control--active--after--BorderBottomWidth); }
    .pf-c-button.pf-m-control:focus {
      --pf-c-button--m-control--Color: var(--pf-c-button--m-control--focus--Color);
      --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--focus--BackgroundColor);
      --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--focus--after--BorderBottomColor); }
      .pf-c-button.pf-m-control:focus::after {
        border-bottom-width: var(--pf-c-button--m-control--focus--after--BorderBottomWidth); }
    .pf-c-button.pf-m-control.pf-m-expanded {
      --pf-c-button--m-control--Color: var(--pf-c-button--m-control--m-expanded--Color);
      --pf-c-button--m-control--BackgroundColor: var(--pf-c-button--m-control--m-expanded--BackgroundColor);
      --pf-c-button--m-control--after--BorderBottomColor: var(--pf-c-button--m-control--m-expanded--after--BorderBottomColor); }
      .pf-c-button.pf-m-control.pf-m-expanded::after {
        border-bottom-width: var(--pf-c-button--m-control--m-expanded--after--BorderBottomWidth); }
  .pf-c-button.pf-m-plain {
    --pf-c-button--disabled--Color: var(--pf-c-button--m-plain--disabled--Color);
    --pf-c-button--disabled--BackgroundColor: var(--pf-c-button--m-plain--disabled--BackgroundColor);
    color: var(--pf-c-button--m-plain--Color);
    background-color: var(--pf-c-button--m-plain--BackgroundColor); }
    .pf-c-button.pf-m-plain:hover {
      --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--hover--Color);
      --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--hover--BackgroundColor); }
    .pf-c-button.pf-m-plain:active, .pf-c-button.pf-m-plain.pf-m-active {
      --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--active--Color);
      --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--active--BackgroundColor); }
    .pf-c-button.pf-m-plain:focus {
      --pf-c-button--m-plain--Color: var(--pf-c-button--m-plain--focus--Color);
      --pf-c-button--m-plain--BackgroundColor: var(--pf-c-button--m-plain--focus--BackgroundColor); }
  .pf-c-button:disabled, .pf-c-button.pf-m-disabled {
    pointer-events: none; }
  .pf-c-button:disabled, .pf-c-button.pf-m-disabled, .pf-c-button.pf-m-aria-disabled {
    --pf-c-button--after--BorderColor: var(--pf-c-button--disabled--after--BorderColor);
    color: var(--pf-c-button--disabled--Color);
    background-color: var(--pf-c-button--disabled--BackgroundColor); }
  .pf-c-button.pf-m-aria-disabled {
    --pf-c-button--after--BorderWidth: 0;
    --pf-c-button--m-link--m-inline--hover--TextDecoration: none;
    cursor: default; }
  .pf-c-button.pf-m-progress {
    --pf-c-button--PaddingRight: var(--pf-c-button--m-progress--PaddingRight);
    --pf-c-button--PaddingLeft: var(--pf-c-button--m-progress--PaddingLeft);
    transition: var(--pf-c-button--m-progress--TransitionProperty) var(--pf-c-button--m-progress--TransitionDuration); }
  .pf-c-button.pf-m-in-progress {
    --pf-c-button--PaddingRight: var(--pf-c-button--m-in-progress--PaddingRight);
    --pf-c-button--PaddingLeft: var(--pf-c-button--m-in-progress--PaddingLeft); }

.pf-c-button__icon.pf-m-start {
  margin-right: var(--pf-c-button__icon--m-start--MarginRight); }

.pf-c-button__icon.pf-m-end {
  margin-left: var(--pf-c-button__icon--m-end--MarginLeft); }

.pf-c-button__progress {
  position: absolute;
  top: var(--pf-c-button__progress--Top);
  left: var(--pf-c-button__progress--Left);
  line-height: 1;
  transform: translateY(var(--pf-c-button__progress--TranslateY)); }
  .pf-c-button__progress .pf-c-spinner {
    --pf-c-spinner--Color: currentColor; }

.pf-m-overpass-font .pf-c-button {
  --pf-c-button--FontWeight: var(--pf-global--FontWeight--semi-bold); }
