.pf-c-popover {
  --pf-c-popover--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-popover--MinWidth: calc(var(--pf-c-popover__content--PaddingLeft) + var(--pf-c-popover__content--PaddingRight) + 18.75rem);
  --pf-c-popover--MaxWidth: calc(var(--pf-c-popover__content--PaddingLeft) + var(--pf-c-popover__content--PaddingRight) + 18.75rem);
  --pf-c-popover--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-popover__content--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-popover__content--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-popover__content--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-popover__content--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-popover__content--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-popover__arrow--Width: var(--pf-global--arrow--width-lg);
  --pf-c-popover__arrow--Height: var(--pf-global--arrow--width-lg);
  --pf-c-popover__arrow--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-popover__arrow--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-popover__arrow--m-top--TranslateX: -50%;
  --pf-c-popover__arrow--m-top--TranslateY: 50%;
  --pf-c-popover__arrow--m-top--Rotate: 45deg;
  --pf-c-popover__arrow--m-right--TranslateX: -50%;
  --pf-c-popover__arrow--m-right--TranslateY: -50%;
  --pf-c-popover__arrow--m-right--Rotate: 45deg;
  --pf-c-popover__arrow--m-bottom--TranslateX: -50%;
  --pf-c-popover__arrow--m-bottom--TranslateY: -50%;
  --pf-c-popover__arrow--m-bottom--Rotate: 45deg;
  --pf-c-popover__arrow--m-left--TranslateX: 50%;
  --pf-c-popover__arrow--m-left--TranslateY: -50%;
  --pf-c-popover__arrow--m-left--Rotate: 45deg;
  --pf-c-popover--c-button--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-popover--c-button--Top: calc(var(--pf-c-popover__content--PaddingTop) - var(--pf-global--spacer--form-element));
  --pf-c-popover--c-button--Right: var(--pf-global--spacer--md);
  --pf-c-popover--c-button--sibling--PaddingRight: var(--pf-global--spacer--2xl);
  --pf-c-popover--c-title--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-popover__footer--MarginTop: var(--pf-global--spacer--md);
  position: relative;
  min-width: var(--pf-c-popover--MinWidth);
  max-width: var(--pf-c-popover--MaxWidth);
  font-size: var(--pf-c-popover--FontSize);
  box-shadow: var(--pf-c-popover--BoxShadow); }
  .pf-c-popover.pf-m-no-padding {
    --pf-c-popover__content--PaddingTop: 0px;
    --pf-c-popover__content--PaddingRight: 0px;
    --pf-c-popover__content--PaddingBottom: 0px;
    --pf-c-popover__content--PaddingLeft: 0px; }
  .pf-c-popover.pf-m-width-auto {
    --pf-c-popover--MinWidth: auto;
    --pf-c-popover--MaxWidth: none; }
  .pf-c-popover.pf-m-top .pf-c-popover__arrow {
    bottom: 0;
    left: 50%;
    transform: translateX(var(--pf-c-popover__arrow--m-top--TranslateX)) translateY(var(--pf-c-popover__arrow--m-top--TranslateY)) rotate(var(--pf-c-popover__arrow--m-top--Rotate)); }
  .pf-c-popover.pf-m-bottom .pf-c-popover__arrow {
    top: 0;
    left: 50%;
    transform: translateX(var(--pf-c-popover__arrow--m-bottom--TranslateX)) translateY(var(--pf-c-popover__arrow--m-bottom--TranslateY)) rotate(var(--pf-c-popover__arrow--m-bottom--Rotate)); }
  .pf-c-popover.pf-m-left .pf-c-popover__arrow {
    top: 50%;
    right: 0;
    transform: translateX(var(--pf-c-popover__arrow--m-left--TranslateX)) translateY(var(--pf-c-popover__arrow--m-left--TranslateY)) rotate(var(--pf-c-popover__arrow--m-left--Rotate)); }
  .pf-c-popover.pf-m-right .pf-c-popover__arrow {
    top: 50%;
    left: 0;
    transform: translateX(var(--pf-c-popover__arrow--m-right--TranslateX)) translateY(var(--pf-c-popover__arrow--m-right--TranslateY)) rotate(var(--pf-c-popover__arrow--m-right--Rotate)); }

.pf-c-popover__content {
  position: relative;
  padding: var(--pf-c-popover__content--PaddingTop) var(--pf-c-popover__content--PaddingRight) var(--pf-c-popover__content--PaddingBottom) var(--pf-c-popover__content--PaddingLeft);
  background-color: var(--pf-c-popover__content--BackgroundColor); }
  .pf-c-popover__content > .pf-c-title {
    margin-bottom: var(--pf-c-popover--c-title--MarginBottom); }
  .pf-c-popover__content > .pf-c-button {
    position: absolute;
    top: var(--pf-c-popover--c-button--Top);
    right: var(--pf-c-popover--c-button--Right); }
    .pf-c-popover__content > .pf-c-button + * {
      padding-right: var(--pf-c-popover--c-button--sibling--PaddingRight); }

.pf-c-popover__arrow {
  position: absolute;
  width: var(--pf-c-popover__arrow--Width);
  height: var(--pf-c-popover__arrow--Height);
  pointer-events: none;
  background-color: var(--pf-c-popover__arrow--BackgroundColor);
  box-shadow: var(--pf-c-popover__arrow--BoxShadow); }

.pf-c-popover__body {
  word-wrap: break-word; }

.pf-c-popover__footer {
  margin-top: var(--pf-c-popover__footer--MarginTop); }
