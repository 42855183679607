.pf-c-toolbar {
  --pf-c-toolbar--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-toolbar--RowGap: var(--pf-global--spacer--lg);
  --pf-c-toolbar--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-toolbar--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-toolbar__content--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-toolbar--m-page-insets--inset: var(--pf-global--spacer--md);
  --pf-c-toolbar--m-page-insets--xl--inset: var(--pf-global--spacer--lg);
  --pf-c-toolbar__expandable-content--PaddingTop: var(--pf-c-toolbar--RowGap);
  --pf-c-toolbar__expandable-content--PaddingRight: var(--pf-c-toolbar__content--PaddingRight);
  --pf-c-toolbar__expandable-content--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-toolbar__expandable-content--PaddingLeft: var(--pf-c-toolbar__content--PaddingLeft);
  --pf-c-toolbar__expandable-content--lg--PaddingRight: 0;
  --pf-c-toolbar__expandable-content--lg--PaddingBottom: 0;
  --pf-c-toolbar__expandable-content--lg--PaddingLeft: 0;
  --pf-c-toolbar__expandable-content--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-toolbar__expandable-content--BoxShadow: var(--pf-global--BoxShadow--md-bottom);
  --pf-c-toolbar__expandable-content--BackgroundColor: var(--pf-c-toolbar--BackgroundColor);
  --pf-c-toolbar__expandable-content--m-expanded--GridRowGap: var(--pf-global--gutter--md);
  --pf-c-toolbar__group--m-chip-container--MarginTop: calc(var(--pf-global--spacer--md) * -1);
  --pf-c-toolbar__group--m-chip-container__item--MarginTop: var(--pf-global--spacer--md);
  --pf-c-toolbar--spacer--base: var(--pf-global--spacer--md);
  --pf-c-toolbar__item--spacer: var(--pf-c-toolbar--spacer--base);
  --pf-c-toolbar__group--spacer: var(--pf-c-toolbar--spacer--base);
  --pf-c-toolbar__group--m-toggle-group--spacer: var(--pf-global--spacer--sm);
  --pf-c-toolbar__group--m-toggle-group--m-show--spacer: var(--pf-c-toolbar__group--spacer);
  --pf-c-toolbar__group--m-icon-button-group--spacer: var(--pf-c-toolbar__group--spacer);
  --pf-c-toolbar__group--m-icon-button-group--space-items: 0;
  --pf-c-toolbar__group--m-button-group--spacer: var(--pf-c-toolbar__group--spacer);
  --pf-c-toolbar__group--m-button-group--space-items: var(--pf-global--spacer--sm);
  --pf-c-toolbar__group--m-filter-group--spacer: var(--pf-c-toolbar__group--spacer);
  --pf-c-toolbar__group--m-filter-group--space-items: 0;
  --pf-c-toolbar__item--m-overflow-menu--spacer: var(--pf-c-toolbar__item--spacer);
  --pf-c-toolbar__item--m-bulk-select--spacer: var(--pf-global--spacer--lg);
  --pf-c-toolbar__expand-all-icon--Rotate: 0;
  --pf-c-toolbar__expand-all-icon--Transition: var(--pf-global--Transition);
  --pf-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate: 90deg;
  --pf-c-toolbar__item--m-search-filter--spacer: var(--pf-global--spacer--sm);
  --pf-c-toolbar__item--m-chip-group--spacer: var(--pf-global--spacer--sm);
  --pf-c-toolbar__item--m-label--spacer: var(--pf-c-toolbar__item--spacer);
  --pf-c-toolbar__item--m-label--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-toolbar__toggle--m-expanded__c-button--m-plain--Color: var(--pf-global--Color--100);
  --pf-c-toolbar--c-divider--m-vertical--spacer: var(--pf-c-toolbar--spacer--base);
  position: relative;
  row-gap: var(--pf-c-toolbar--RowGap);
  display: grid;
  padding-top: var(--pf-c-toolbar--PaddingTop);
  padding-bottom: var(--pf-c-toolbar--PaddingBottom);
  background-color: var(--pf-c-toolbar--BackgroundColor); }
  @media screen and (min-width: 992px) {
    .pf-c-toolbar {
      --pf-c-toolbar__expandable-content--PaddingRight: var(--pf-c-toolbar__expandable-content--lg--PaddingRight);
      --pf-c-toolbar__expandable-content--PaddingBottom: var(--pf-c-toolbar__expandable-content--lg--PaddingBottom);
      --pf-c-toolbar__expandable-content--PaddingLeft: var(--pf-c-toolbar__expandable-content--lg--PaddingLeft); } }
  @media screen and (min-width: 1200px) {
    .pf-c-toolbar {
      --pf-c-toolbar--m-page-insets--inset: var(--pf-c-toolbar--m-page-insets--xl--inset); } }
  .pf-c-toolbar.pf-m-page-insets {
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--m-page-insets--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--m-page-insets--inset); }

.pf-c-toolbar__content-section > .pf-c-divider,
.pf-c-toolbar__group > .pf-c-divider {
  --pf-c-toolbar--spacer: var(--pf-c-toolbar--c-divider--m-vertical--spacer); }

.pf-c-toolbar__content-section > .pf-c-divider.pf-m-vertical,
.pf-c-toolbar__group > .pf-c-divider.pf-m-vertical {
  margin-right: var(--pf-c-toolbar--spacer); }
  .pf-c-toolbar__content-section > .pf-c-divider.pf-m-vertical:last-child,
  .pf-c-toolbar__group > .pf-c-divider.pf-m-vertical:last-child {
    --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar__group {
  --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--spacer);
  display: flex;
  align-items: center;
  margin-right: var(--pf-c-toolbar--spacer); }
  .pf-c-toolbar__group.pf-m-button-group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-button-group--spacer); }
    .pf-c-toolbar__group.pf-m-button-group > * {
      --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-button-group--space-items); }
  .pf-c-toolbar__group.pf-m-icon-button-group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-icon-button-group--spacer); }
    .pf-c-toolbar__group.pf-m-icon-button-group > * {
      --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-icon-button-group--space-items); }
  .pf-c-toolbar__group.pf-m-filter-group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-filter-group--spacer); }
    .pf-c-toolbar__group.pf-m-filter-group > * {
      --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-filter-group--space-items); }
    .pf-c-toolbar__group.pf-m-filter-group > * + * {
      margin-left: -1px; }
  .pf-c-toolbar__group.pf-m-toggle-group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--spacer); }
    .pf-c-toolbar__group.pf-m-toggle-group .pf-c-toolbar__group,
    .pf-c-toolbar__group.pf-m-toggle-group .pf-c-toolbar__item {
      display: none;
      visibility: hidden; }
    .pf-c-toolbar__group.pf-m-toggle-group .pf-c-toolbar__toggle {
      display: inline-block;
      visibility: visible; }
  .pf-c-toolbar__group:last-child {
    --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar__item {
  --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--spacer);
  margin-right: var(--pf-c-toolbar--spacer); }
  .pf-c-toolbar__item.pf-m-overflow-menu {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--m-overflow-menu--spacer); }
  .pf-c-toolbar__item.pf-m-bulk-select {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--m-bulk-select--spacer); }
  .pf-c-toolbar__item.pf-m-expand-all.pf-m-expanded {
    --pf-c-toolbar__expand-all-icon--Rotate: var(--pf-c-toolbar__item--m-expand-all--m-expanded__expand-all-icon--Rotate); }
  .pf-c-toolbar__item.pf-m-search-filter {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--m-search-filter--spacer); }
  .pf-c-toolbar__item.pf-m-chip-group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--m-chip-group--spacer); }
  .pf-c-toolbar__item.pf-m-label {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--m-label--spacer);
    font-weight: var(--pf-c-toolbar__item--m-label--FontWeight); }
  .pf-c-toolbar__item.pf-m-pagination {
    margin-left: auto; }
    .pf-c-toolbar__item.pf-m-pagination .pf-c-pagination {
      flex-wrap: nowrap; }
  .pf-c-toolbar__item:last-child {
    --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar__expand-all-icon {
  display: inline-block;
  transition: var(--pf-c-toolbar__expand-all-icon--Transition);
  transform: rotate(var(--pf-c-toolbar__expand-all-icon--Rotate)); }

.pf-c-toolbar__content,
.pf-c-toolbar__content-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.pf-c-toolbar__content {
  position: relative;
  padding-right: var(--pf-c-toolbar__content--PaddingRight);
  padding-left: var(--pf-c-toolbar__content--PaddingLeft); }

.pf-c-toolbar__content-section {
  width: 100%; }

.pf-c-toolbar__expandable-content {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: var(--pf-c-toolbar__expandable-content--ZIndex);
  display: none;
  width: 100%;
  padding: var(--pf-c-toolbar__expandable-content--PaddingTop) var(--pf-c-toolbar__expandable-content--PaddingRight) var(--pf-c-toolbar__expandable-content--PaddingBottom) var(--pf-c-toolbar__expandable-content--PaddingLeft);
  visibility: hidden;
  background-color: var(--pf-c-toolbar__expandable-content--BackgroundColor);
  box-shadow: var(--pf-c-toolbar__expandable-content--BoxShadow); }
  @media screen and (min-width: 992px) {
    .pf-c-toolbar__expandable-content {
      position: static;
      box-shadow: none; } }
  .pf-c-toolbar__expandable-content.pf-m-expanded {
    display: grid;
    grid-row-gap: var(--pf-c-toolbar__expandable-content--m-expanded--GridRowGap);
    visibility: visible; }
  .pf-c-toolbar__expandable-content .pf-c-toolbar__group,
  .pf-c-toolbar__expandable-content .pf-c-toolbar__item {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar__expandable-content .pf-c-toolbar__group {
    display: grid;
    grid-row-gap: var(--pf-c-toolbar__expandable-content--m-expanded--GridRowGap); }
  .pf-c-toolbar__expandable-content .pf-m-label {
    display: none;
    visibility: hidden; }

.pf-c-toolbar__content.pf-m-chip-container,
.pf-c-toolbar__group.pf-m-chip-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: var(--pf-c-toolbar__group--m-chip-container--MarginTop);
  grid-row-gap: 0; }
  .pf-c-toolbar__content.pf-m-chip-container .pf-c-toolbar__item,
  .pf-c-toolbar__group.pf-m-chip-container .pf-c-toolbar__item {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__item--spacer);
    margin-top: var(--pf-c-toolbar__group--m-chip-container__item--MarginTop); }
  .pf-c-toolbar__content.pf-m-chip-container .pf-c-toolbar__group,
  .pf-c-toolbar__group.pf-m-chip-container .pf-c-toolbar__group {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--spacer);
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 0; }
  .pf-c-toolbar__content.pf-m-chip-container .pf-c-toolbar__group:last-child,
  .pf-c-toolbar__content.pf-m-chip-container .pf-c-toolbar__item:last-child,
  .pf-c-toolbar__group.pf-m-chip-container .pf-c-toolbar__group:last-child,
  .pf-c-toolbar__group.pf-m-chip-container .pf-c-toolbar__item:last-child {
    --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-c-chip-group:last-child {
  --pf-c-chip-group--MarginRight: 0; }

.pf-c-toolbar .pf-c-chip-group li:last-child {
  --pf-c-chip-group__li--m-toolbar--MarginRight: 0; }

.pf-c-toolbar__toggle.pf-m-expanded .pf-c-button.pf-m-plain {
  color: var(--pf-c-toolbar__toggle--m-expanded__c-button--m-plain--Color); }

.pf-m-toggle-group.pf-m-show {
  --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
  .pf-m-toggle-group.pf-m-show .pf-c-toolbar__group,
  .pf-m-toggle-group.pf-m-show .pf-c-toolbar__item {
    display: flex;
    flex: 0 1 auto;
    visibility: visible; }
  .pf-m-toggle-group.pf-m-show .pf-c-toolbar__toggle {
    display: none;
    visibility: hidden; }

@media (min-width: 576px) {
  .pf-m-toggle-group.pf-m-show-on-sm {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
    .pf-m-toggle-group.pf-m-show-on-sm .pf-c-toolbar__group,
    .pf-m-toggle-group.pf-m-show-on-sm .pf-c-toolbar__item {
      display: flex;
      flex: 0 1 auto;
      visibility: visible; }
    .pf-m-toggle-group.pf-m-show-on-sm .pf-c-toolbar__toggle {
      display: none;
      visibility: hidden; } }

@media (min-width: 768px) {
  .pf-m-toggle-group.pf-m-show-on-md {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
    .pf-m-toggle-group.pf-m-show-on-md .pf-c-toolbar__group,
    .pf-m-toggle-group.pf-m-show-on-md .pf-c-toolbar__item {
      display: flex;
      flex: 0 1 auto;
      visibility: visible; }
    .pf-m-toggle-group.pf-m-show-on-md .pf-c-toolbar__toggle {
      display: none;
      visibility: hidden; } }

@media (min-width: 992px) {
  .pf-m-toggle-group.pf-m-show-on-lg {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
    .pf-m-toggle-group.pf-m-show-on-lg .pf-c-toolbar__group,
    .pf-m-toggle-group.pf-m-show-on-lg .pf-c-toolbar__item {
      display: flex;
      flex: 0 1 auto;
      visibility: visible; }
    .pf-m-toggle-group.pf-m-show-on-lg .pf-c-toolbar__toggle {
      display: none;
      visibility: hidden; } }

@media (min-width: 1200px) {
  .pf-m-toggle-group.pf-m-show-on-xl {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
    .pf-m-toggle-group.pf-m-show-on-xl .pf-c-toolbar__group,
    .pf-m-toggle-group.pf-m-show-on-xl .pf-c-toolbar__item {
      display: flex;
      flex: 0 1 auto;
      visibility: visible; }
    .pf-m-toggle-group.pf-m-show-on-xl .pf-c-toolbar__toggle {
      display: none;
      visibility: hidden; } }

@media (min-width: 1450px) {
  .pf-m-toggle-group.pf-m-show-on-2xl {
    --pf-c-toolbar--spacer: var(--pf-c-toolbar__group--m-toggle-group--m-show--spacer); }
    .pf-m-toggle-group.pf-m-show-on-2xl .pf-c-toolbar__group,
    .pf-m-toggle-group.pf-m-show-on-2xl .pf-c-toolbar__item {
      display: flex;
      flex: 0 1 auto;
      visibility: visible; }
    .pf-m-toggle-group.pf-m-show-on-2xl .pf-c-toolbar__toggle {
      display: none;
      visibility: hidden; } }

.pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right,
.pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right {
  margin-left: auto; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right ~ .pf-m-pagination,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right ~ .pf-m-pagination {
    margin-left: 0; }

.pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left,
.pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left {
  margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left ~ .pf-m-pagination,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left ~ .pf-m-pagination {
    margin-left: auto; }

.pf-c-toolbar .pf-m-hidden {
  display: none;
  visibility: hidden; }

.pf-c-toolbar .pf-m-visible {
  display: flex;
  visibility: visible; }

.pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap,
.pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap {
  flex-wrap: nowrap; }

.pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap,
.pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap {
  flex-wrap: wrap; }

@media (min-width: 576px) {
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-sm,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-sm {
    margin-left: auto; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-sm ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-sm ~ .pf-m-pagination {
      margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-sm,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-sm {
    margin-left: 0; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-sm ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-sm ~ .pf-m-pagination {
      margin-left: auto; }
  .pf-c-toolbar .pf-m-hidden-on-sm {
    display: none;
    visibility: hidden; }
  .pf-c-toolbar .pf-m-visible-on-sm {
    display: flex;
    visibility: visible; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap-on-sm,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap-on-sm {
    flex-wrap: nowrap; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap-on-sm,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap-on-sm {
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-md,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-md {
    margin-left: auto; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-md ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-md ~ .pf-m-pagination {
      margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-md,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-md {
    margin-left: 0; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-md ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-md ~ .pf-m-pagination {
      margin-left: auto; }
  .pf-c-toolbar .pf-m-hidden-on-md {
    display: none;
    visibility: hidden; }
  .pf-c-toolbar .pf-m-visible-on-md {
    display: flex;
    visibility: visible; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap-on-md,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap-on-md {
    flex-wrap: nowrap; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap-on-md,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap-on-md {
    flex-wrap: wrap; } }

@media (min-width: 992px) {
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-lg,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-lg {
    margin-left: auto; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-lg ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-lg ~ .pf-m-pagination {
      margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-lg,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-lg {
    margin-left: 0; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-lg ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-lg ~ .pf-m-pagination {
      margin-left: auto; }
  .pf-c-toolbar .pf-m-hidden-on-lg {
    display: none;
    visibility: hidden; }
  .pf-c-toolbar .pf-m-visible-on-lg {
    display: flex;
    visibility: visible; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap-on-lg,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap-on-lg {
    flex-wrap: nowrap; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap-on-lg,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap-on-lg {
    flex-wrap: wrap; } }

@media (min-width: 1200px) {
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-xl {
    margin-left: auto; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-xl ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-xl ~ .pf-m-pagination {
      margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-xl {
    margin-left: 0; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-xl ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-xl ~ .pf-m-pagination {
      margin-left: auto; }
  .pf-c-toolbar .pf-m-hidden-on-xl {
    display: none;
    visibility: hidden; }
  .pf-c-toolbar .pf-m-visible-on-xl {
    display: flex;
    visibility: visible; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap-on-xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap-on-xl {
    flex-wrap: nowrap; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap-on-xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap-on-xl {
    flex-wrap: wrap; } }

@media (min-width: 1450px) {
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-2xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-2xl {
    margin-left: auto; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-right-on-2xl ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-right-on-2xl ~ .pf-m-pagination {
      margin-left: 0; }
  .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-2xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-2xl {
    margin-left: 0; }
    .pf-c-toolbar .pf-c-toolbar__item.pf-m-align-left-on-2xl ~ .pf-m-pagination,
    .pf-c-toolbar .pf-c-toolbar__group.pf-m-align-left-on-2xl ~ .pf-m-pagination {
      margin-left: auto; }
  .pf-c-toolbar .pf-m-hidden-on-2xl {
    display: none;
    visibility: hidden; }
  .pf-c-toolbar .pf-m-visible-on-2xl {
    display: flex;
    visibility: visible; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-nowrap-on-2xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-nowrap-on-2xl {
    flex-wrap: nowrap; }
  .pf-c-toolbar .pf-c-toolbar__content-section.pf-m-wrap-on-2xl,
  .pf-c-toolbar .pf-c-toolbar__group.pf-m-wrap-on-2xl {
    flex-wrap: wrap; } }

.pf-c-toolbar .pf-m-space-items-none > * {
  --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-m-space-items-none > :last-child {
  --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-m-space-items-sm > * {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }

.pf-c-toolbar .pf-m-space-items-sm > :last-child {
  --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-m-space-items-md > * {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }

.pf-c-toolbar .pf-m-space-items-md > :last-child {
  --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-m-space-items-lg > * {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }

.pf-c-toolbar .pf-m-space-items-lg > :last-child {
  --pf-c-toolbar--spacer: 0; }

@media (min-width: 576px) {
  .pf-c-toolbar .pf-m-space-items-none-on-sm > * {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-none-on-sm > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-sm-on-sm > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-space-items-sm-on-sm > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-md-on-sm > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-space-items-md-on-sm > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-lg-on-sm > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-space-items-lg-on-sm > :last-child {
    --pf-c-toolbar--spacer: 0; } }

@media (min-width: 768px) {
  .pf-c-toolbar .pf-m-space-items-none-on-md > * {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-none-on-md > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-sm-on-md > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-space-items-sm-on-md > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-md-on-md > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-space-items-md-on-md > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-lg-on-md > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-space-items-lg-on-md > :last-child {
    --pf-c-toolbar--spacer: 0; } }

@media (min-width: 992px) {
  .pf-c-toolbar .pf-m-space-items-none-on-lg > * {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-none-on-lg > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-sm-on-lg > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-space-items-sm-on-lg > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-md-on-lg > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-space-items-md-on-lg > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-lg-on-lg > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-space-items-lg-on-lg > :last-child {
    --pf-c-toolbar--spacer: 0; } }

@media (min-width: 1200px) {
  .pf-c-toolbar .pf-m-space-items-none-on-xl > * {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-none-on-xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-sm-on-xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-space-items-sm-on-xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-md-on-xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-space-items-md-on-xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-lg-on-xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-space-items-lg-on-xl > :last-child {
    --pf-c-toolbar--spacer: 0; } }

@media (min-width: 1450px) {
  .pf-c-toolbar .pf-m-space-items-none-on-2xl > * {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-none-on-2xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-sm-on-2xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-space-items-sm-on-2xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-md-on-2xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-space-items-md-on-2xl > :last-child {
    --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-space-items-lg-on-2xl > * {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-space-items-lg-on-2xl > :last-child {
    --pf-c-toolbar--spacer: 0; } }

.pf-c-toolbar .pf-m-spacer-none {
  --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-none:last-child {
    --pf-c-toolbar--spacer: 0; }

.pf-c-toolbar .pf-m-spacer-sm {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-sm:last-child {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }

.pf-c-toolbar .pf-m-spacer-md {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-md:last-child {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }

.pf-c-toolbar .pf-m-spacer-lg {
  --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
  .pf-c-toolbar .pf-m-spacer-lg:last-child {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }

@media (min-width: 576px) {
  .pf-c-toolbar .pf-m-spacer-none-on-sm {
    --pf-c-toolbar--spacer: 0; }
    .pf-c-toolbar .pf-m-spacer-none-on-sm:last-child {
      --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-sm-on-sm {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
    .pf-c-toolbar .pf-m-spacer-sm-on-sm:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-md-on-sm {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
    .pf-c-toolbar .pf-m-spacer-md-on-sm:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-lg-on-sm {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
    .pf-c-toolbar .pf-m-spacer-lg-on-sm:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); } }

@media (min-width: 768px) {
  .pf-c-toolbar .pf-m-spacer-none-on-md {
    --pf-c-toolbar--spacer: 0; }
    .pf-c-toolbar .pf-m-spacer-none-on-md:last-child {
      --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-sm-on-md {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
    .pf-c-toolbar .pf-m-spacer-sm-on-md:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-md-on-md {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
    .pf-c-toolbar .pf-m-spacer-md-on-md:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-lg-on-md {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
    .pf-c-toolbar .pf-m-spacer-lg-on-md:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); } }

@media (min-width: 992px) {
  .pf-c-toolbar .pf-m-spacer-none-on-lg {
    --pf-c-toolbar--spacer: 0; }
    .pf-c-toolbar .pf-m-spacer-none-on-lg:last-child {
      --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-sm-on-lg {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
    .pf-c-toolbar .pf-m-spacer-sm-on-lg:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-md-on-lg {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
    .pf-c-toolbar .pf-m-spacer-md-on-lg:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-lg-on-lg {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
    .pf-c-toolbar .pf-m-spacer-lg-on-lg:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); } }

@media (min-width: 1200px) {
  .pf-c-toolbar .pf-m-spacer-none-on-xl {
    --pf-c-toolbar--spacer: 0; }
    .pf-c-toolbar .pf-m-spacer-none-on-xl:last-child {
      --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-sm-on-xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
    .pf-c-toolbar .pf-m-spacer-sm-on-xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-md-on-xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
    .pf-c-toolbar .pf-m-spacer-md-on-xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-lg-on-xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
    .pf-c-toolbar .pf-m-spacer-lg-on-xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); } }

@media (min-width: 1450px) {
  .pf-c-toolbar .pf-m-spacer-none-on-2xl {
    --pf-c-toolbar--spacer: 0; }
    .pf-c-toolbar .pf-m-spacer-none-on-2xl:last-child {
      --pf-c-toolbar--spacer: 0; }
  .pf-c-toolbar .pf-m-spacer-sm-on-2xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
    .pf-c-toolbar .pf-m-spacer-sm-on-2xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--sm); }
  .pf-c-toolbar .pf-m-spacer-md-on-2xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
    .pf-c-toolbar .pf-m-spacer-md-on-2xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--md); }
  .pf-c-toolbar .pf-m-spacer-lg-on-2xl {
    --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); }
    .pf-c-toolbar .pf-m-spacer-lg-on-2xl:last-child {
      --pf-c-toolbar--spacer: var(--pf-global--spacer--lg); } }

.pf-c-toolbar.pf-m-inset-none {
  --pf-c-toolbar--inset: 0;
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

.pf-c-toolbar.pf-m-inset-sm {
  --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

.pf-c-toolbar.pf-m-inset-md {
  --pf-c-toolbar--inset: var(--pf-global--spacer--md);
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

.pf-c-toolbar.pf-m-inset-lg {
  --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

.pf-c-toolbar.pf-m-inset-xl {
  --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

.pf-c-toolbar.pf-m-inset-2xl {
  --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
  --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
  --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }

@media (min-width: 576px) {
  .pf-c-toolbar.pf-m-inset-none-on-sm {
    --pf-c-toolbar--inset: 0;
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-sm-on-sm {
    --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-md-on-sm {
    --pf-c-toolbar--inset: var(--pf-global--spacer--md);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-lg-on-sm {
    --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-xl-on-sm {
    --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-2xl-on-sm {
    --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); } }

@media (min-width: 768px) {
  .pf-c-toolbar.pf-m-inset-none-on-md {
    --pf-c-toolbar--inset: 0;
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-sm-on-md {
    --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-md-on-md {
    --pf-c-toolbar--inset: var(--pf-global--spacer--md);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-lg-on-md {
    --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-xl-on-md {
    --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-2xl-on-md {
    --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); } }

@media (min-width: 992px) {
  .pf-c-toolbar.pf-m-inset-none-on-lg {
    --pf-c-toolbar--inset: 0;
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-sm-on-lg {
    --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-md-on-lg {
    --pf-c-toolbar--inset: var(--pf-global--spacer--md);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-lg-on-lg {
    --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-xl-on-lg {
    --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-2xl-on-lg {
    --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); } }

@media (min-width: 1200px) {
  .pf-c-toolbar.pf-m-inset-none-on-xl {
    --pf-c-toolbar--inset: 0;
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-sm-on-xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-md-on-xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--md);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-lg-on-xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-xl-on-xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-2xl-on-xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); } }

@media (min-width: 1450px) {
  .pf-c-toolbar.pf-m-inset-none-on-2xl {
    --pf-c-toolbar--inset: 0;
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-sm-on-2xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--sm);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-md-on-2xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--md);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-lg-on-2xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--lg);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-xl-on-2xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); }
  .pf-c-toolbar.pf-m-inset-2xl-on-2xl {
    --pf-c-toolbar--inset: var(--pf-global--spacer--2xl);
    --pf-c-toolbar__content--PaddingRight: var(--pf-c-toolbar--inset);
    --pf-c-toolbar__content--PaddingLeft: var(--pf-c-toolbar--inset); } }

.pf-c-toolbar__content-section > :last-child {
  --pf-c-toolbar--spacer: 0; }
