.pf-c-empty-state {
  --pf-c-empty-state--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-empty-state--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-empty-state--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-empty-state--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-empty-state__content--MaxWidth: none;
  --pf-c-empty-state__icon--MarginBottom: var(--pf-global--spacer--lg);
  --pf-c-empty-state__icon--FontSize: var(--pf-global--icon--FontSize--xl);
  --pf-c-empty-state__icon--Color: var(--pf-global--icon--Color--light);
  --pf-c-empty-state__content--c-title--m-lg--FontSize: var(--pf-global--FontSize--xl);
  --pf-c-empty-state__body--MarginTop: var(--pf-global--spacer--md);
  --pf-c-empty-state__body--Color: var(--pf-global--Color--200);
  --pf-c-empty-state__primary--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-empty-state__primary--secondary--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-empty-state__secondary--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-empty-state__secondary--MarginBottom: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-empty-state__secondary--child--MarginRight: calc(var(--pf-global--spacer--xs) / 2);
  --pf-c-empty-state__secondary--child--MarginBottom: var(--pf-global--spacer--xs);
  --pf-c-empty-state__secondary--child--MarginLeft: calc(var(--pf-global--spacer--xs) / 2);
  --pf-c-empty-state--m-xs__content--MaxWidth: 21.875rem;
  --pf-c-empty-state--m-xs__body--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-empty-state--m-xs--button--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-empty-state--m-xs--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs__icon--MarginBottom: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs__body--MarginTop: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs__primary--MarginTop: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-xs__secondary--MarginTop: var(--pf-global--spacer--md);
  --pf-c-empty-state--m-sm__content--MaxWidth: 25rem;
  --pf-c-empty-state--m-lg__content--MaxWidth: 37.5rem;
  --pf-c-empty-state--m-xl__body--FontSize: var(--pf-global--FontSize--xl);
  --pf-c-empty-state--m-xl__body--MarginTop: var(--pf-global--spacer--lg);
  --pf-c-empty-state--m-xl__icon--MarginBottom: var(--pf-global--spacer--xl);
  --pf-c-empty-state--m-xl__icon--FontSize: 6.25rem;
  --pf-c-empty-state--m-xl--c-button__secondary--MarginTop: var(--pf-global--spacer--md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--pf-c-empty-state--PaddingTop) var(--pf-c-empty-state--PaddingRight) var(--pf-c-empty-state--PaddingBottom) var(--pf-c-empty-state--PaddingLeft);
  text-align: center; }
  .pf-c-empty-state.pf-m-xs {
    --pf-c-empty-state--PaddingTop: var(--pf-c-empty-state--m-xs--PaddingTop);
    --pf-c-empty-state--PaddingRight: var(--pf-c-empty-state--m-xs--PaddingRight);
    --pf-c-empty-state--PaddingBottom: var(--pf-c-empty-state--m-xs--PaddingBottom);
    --pf-c-empty-state--PaddingLeft: var(--pf-c-empty-state--m-xs--PaddingLeft);
    --pf-c-empty-state__content--MaxWidth: var(--pf-c-empty-state--m-xs__content--MaxWidth);
    --pf-c-empty-state__icon--MarginBottom: var(--pf-c-empty-state--m-xs__icon--MarginBottom);
    --pf-c-empty-state__body--MarginTop: var(--pf-c-empty-state--m-xs__body--MarginTop);
    --pf-c-empty-state__primary--MarginTop: var(--pf-c-empty-state--m-xs__primary--MarginTop);
    --pf-c-empty-state__secondary--MarginTop: var(--pf-c-empty-state--m-xs__secondary--MarginTop); }
    .pf-c-empty-state.pf-m-xs .pf-c-empty-state__body {
      font-size: var(--pf-c-empty-state--m-xs__body--FontSize); }
    .pf-c-empty-state.pf-m-xs .pf-c-button {
      --pf-c-button--FontSize: var(--pf-c-empty-state--m-xs--button--FontSize); }
  .pf-c-empty-state.pf-m-sm {
    --pf-c-empty-state__content--MaxWidth: var(--pf-c-empty-state--m-sm__content--MaxWidth); }
  .pf-c-empty-state.pf-m-lg {
    --pf-c-empty-state__content--MaxWidth: var(--pf-c-empty-state--m-lg__content--MaxWidth); }
  .pf-c-empty-state.pf-m-xl {
    --pf-c-empty-state__body--MarginTop: var(--pf-c-empty-state--m-xl__body--MarginTop);
    --pf-c-empty-state__icon--MarginBottom: var(--pf-c-empty-state--m-xl__icon--MarginBottom);
    --pf-c-empty-state__icon--FontSize: var(--pf-c-empty-state--m-xl__icon--FontSize);
    --pf-c-empty-state--c-button__secondary--MarginTop: var(--pf-c-empty-state--m-xl--c-button__secondary--MarginTop); }
    .pf-c-empty-state.pf-m-xl .pf-c-empty-state__body {
      font-size: var(--pf-c-empty-state--m-xl__body--FontSize); }
  .pf-c-empty-state.pf-m-full-height {
    height: 100%; }

.pf-c-empty-state__content {
  max-width: var(--pf-c-empty-state__content--MaxWidth); }
  .pf-c-empty-state__content > .pf-c-title.pf-m-lg {
    font-size: var(--pf-c-empty-state__content--c-title--m-lg--FontSize); }

.pf-c-empty-state__icon {
  margin-bottom: var(--pf-c-empty-state__icon--MarginBottom);
  font-size: var(--pf-c-empty-state__icon--FontSize);
  color: var(--pf-c-empty-state__icon--Color); }

.pf-c-empty-state__body {
  margin-top: var(--pf-c-empty-state__body--MarginTop);
  color: var(--pf-c-empty-state__body--Color); }

.pf-c-empty-state__content > .pf-c-button.pf-m-primary,
.pf-c-empty-state__primary {
  margin-top: var(--pf-c-empty-state__primary--MarginTop); }
  .pf-c-empty-state__content > .pf-c-button.pf-m-primary + .pf-c-empty-state__secondary,
  .pf-c-empty-state__primary + .pf-c-empty-state__secondary {
    margin-top: var(--pf-c-empty-state__primary--secondary--MarginTop); }

.pf-c-empty-state__secondary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--pf-c-empty-state__secondary--MarginTop);
  margin-bottom: var(--pf-c-empty-state__secondary--MarginBottom); }
  .pf-c-empty-state__secondary > * {
    margin-right: var(--pf-c-empty-state__secondary--child--MarginRight);
    margin-bottom: var(--pf-c-empty-state__secondary--child--MarginBottom);
    margin-left: var(--pf-c-empty-state__secondary--child--MarginLeft); }

.pf-m-overpass-font .pf-c-empty-state .pf-c-empty-state__content > .pf-c-title.pf-m-lg {
  font-size: var(--pf-global--FontSize--lg); }
