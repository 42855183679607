.pf-c-table[class*="pf-m-grid"] {
  --pf-c-table--responsive--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-table--tbody--responsive--border-width--base: var(--pf-global--spacer--sm);
  --pf-c-table--tbody--after--border-width--base: var(--pf-global--BorderWidth--lg);
  --pf-c-table--tbody--after--BorderLeftWidth: 0;
  --pf-c-table--tbody--after--BorderColor: var(--pf-global--active-color--100);
  --pf-c-table-tr--responsive--border-width--base: var(--pf-global--spacer--sm);
  --pf-c-table-tr--responsive--last-child--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-table-tr--responsive--GridColumnGap: var(--pf-global--spacer--md);
  --pf-c-table-tr--responsive--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-table-tr--responsive--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-table-tr--responsive--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-table-tr--responsive--xl--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-table-tr--responsive--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-table-tr--responsive--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-table-tr--responsive--xl--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-table-tr--responsive--nested-table--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-table-tr--responsive--nested-table--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-table-tr--responsive--nested-table--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-table-tr--responsive--nested-table--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-table--m-grid--cell--hidden-visible--Display: grid;
  --pf-c-table--m-grid--cell--PaddingTop: 0;
  --pf-c-table--m-grid--cell--PaddingRight: 0;
  --pf-c-table--m-grid--cell--PaddingBottom: 0;
  --pf-c-table--m-grid--cell--PaddingLeft: 0;
  --pf-c-table-td--responsive--GridColumnGap: var(--pf-global--spacer--md);
  --pf-c-table--cell--responsive--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-table--cell--responsive--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-table--cell--first-child--responsive--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-table--cell--responsive--PaddingRight: 0;
  --pf-c-table--cell--responsive--PaddingLeft: 0;
  --pf-c-table--m-compact-tr--responsive--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-table--m-compact-tr--responsive--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-table--m-compact-tr-td--responsive--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-table--m-compact-tr-td--responsive--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-table--m-compact__action--responsive--MarginTop: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-table--m-compact__action--responsive--MarginBottom: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom: calc(0.375rem * -1);
  --pf-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-table__expandable-row-content--responsive--xl--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-table__expandable-row-content--responsive--xl--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-table__expandable-row-content--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-table__check--responsive--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-table__check--responsive--MarginTop: 0.875rem;
  --pf-c-table--m-grid__favorite--MarginTop: 0.5rem;
  --pf-c-table--m-grid__check--favorite--MarginLeft: var(--pf-global--spacer--xl);
  --pf-c-table--m-grid__action--MarginTop: 0.375rem;
  --pf-c-table__action--responsive--MarginLeft: var(--pf-global--spacer--xl);
  --pf-c-table--m-grid__favorite--action--MarginLeft: var(--pf-global--spacer--2xl);
  --pf-c-table--m-grid__check--favorite--action--MarginLeft: calc(var(--pf-c-table--m-grid__check--favorite--MarginLeft) + var(--pf-c-table--m-grid__favorite--action--MarginLeft));
  --pf-c-table__toggle__icon--Transition: .2s ease-in 0s;
  --pf-c-table__toggle--m-expanded__icon--Rotate: 180deg; }
  @media screen and (max-width: 1200px) {
    .pf-c-table[class*="pf-m-grid"] {
      --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--xl--PaddingRight);
      --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--xl--PaddingLeft); } }
  @media screen and (max-width: 1200px) {
    .pf-c-table[class*="pf-m-grid"] {
      --pf-c-table__expandable-row-content--responsive--PaddingRight: var(--pf-c-table__expandable-row-content--responsive--xl--PaddingRight);
      --pf-c-table__expandable-row-content--responsive--PaddingLeft: var(--pf-c-table__expandable-row-content--responsive--xl--PaddingLeft); } }

.pf-m-grid.pf-c-table {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
  --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
  --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
  --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft);
  --pf-c-table__favorite--c-button--MarginTop: auto;
  --pf-c-table__favorite--c-button--MarginRight: auto;
  --pf-c-table__favorite--c-button--MarginBottom: auto;
  --pf-c-table__favorite--c-button--MarginLeft: auto;
  display: grid;
  border: none; }
  .pf-m-grid.pf-c-table tr > * {
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: visible;
    text-overflow: clip;
    white-space: normal; }
  .pf-m-grid.pf-c-table .pf-c-table__text {
    position: relative;
    width: auto;
    min-width: 0;
    max-width: none;
    overflow: var(--pf-c-table--cell--Overflow);
    text-overflow: var(--pf-c-table--cell--TextOverflow);
    white-space: var(--pf-c-table--cell--WhiteSpace); }
  .pf-m-grid.pf-c-table thead {
    display: none;
    visibility: hidden; }
  .pf-m-grid.pf-c-table tbody {
    display: block; }
    .pf-m-grid.pf-c-table tbody:first-of-type {
      border-top: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
  .pf-m-grid.pf-c-table table.pf-m-compact > tbody {
    border-top: 0; }
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) {
    border-bottom: var(--pf-c-table-tr--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
  .pf-m-grid.pf-c-table tr:last-child,
  .pf-m-grid.pf-c-table tbody:last-of-type:not(:only-of-type) > tr {
    border-bottom-width: var(--pf-c-table-tr--responsive--last-child--BorderBottomWidth); }
  .pf-m-grid.pf-c-table tbody.pf-m-expanded {
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor); }
    .pf-m-grid.pf-c-table tbody.pf-m-expanded tr:not(.pf-c-table__expandable-row) {
      border-bottom: 0; }
    .pf-m-grid.pf-c-table tbody.pf-m-expanded:not(:last-of-type) {
      border-bottom: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    grid-auto-columns: max-content;
    grid-column-gap: var(--pf-c-table-tr--responsive--GridColumnGap);
    padding: var(--pf-c-table-tr--responsive--PaddingTop) var(--pf-c-table-tr--responsive--PaddingRight) var(--pf-c-table-tr--responsive--PaddingBottom) var(--pf-c-table-tr--responsive--PaddingLeft); }
    .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) > * {
      padding: var(--pf-c-table--cell--responsive--PaddingTop) var(--pf-c-table--cell--responsive--PaddingRight) var(--pf-c-table--cell--responsive--PaddingBottom) var(--pf-c-table--cell--responsive--PaddingLeft); }
      .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) > *:first-child {
        --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--cell--first-child--responsive--PaddingTop); }
  .pf-m-grid.pf-c-table.pf-m-compact {
    --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table--m-compact-tr--responsive--PaddingTop);
    --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr--responsive--PaddingBottom);
    --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--m-compact-tr-td--responsive--PaddingTop);
    --pf-c-table--cell--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr-td--responsive--PaddingBottom);
    --pf-c-table__check--input--MarginTop: 0; }
    .pf-m-grid.pf-c-table.pf-m-compact .pf-c-table__action {
      margin-top: var(--pf-c-table--m-compact__action--responsive--MarginTop);
      margin-bottom: var(--pf-c-table--m-compact__action--responsive--MarginTop); }
    .pf-m-grid.pf-c-table.pf-m-compact .pf-c-table__toggle .pf-c-button {
      margin-bottom: var(--pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom); }
  .pf-m-grid.pf-c-table .pf-c-table__icon > * {
    text-align: left; }
  .pf-m-grid.pf-c-table [data-label] {
    --pf-c-table--cell--hidden-visible--Display: var(--pf-c-table--m-grid--cell--hidden-visible--Display);
    grid-column: 1;
    grid-column-gap: var(--pf-c-table-td--responsive--GridColumnGap);
    grid-template-columns: 1fr minmax(0, 1.5fr);
    align-items: start; }
    .pf-m-grid.pf-c-table [data-label] > * {
      grid-column: 2; }
    .pf-m-grid.pf-c-table [data-label]::before {
      font-weight: bold;
      text-align: left;
      content: attr(data-label); }
  .pf-m-grid.pf-c-table tr > *:first-child {
    --pf-c-table--cell--PaddingLeft: 0; }
  .pf-m-grid.pf-c-table tr > *:last-child {
    --pf-c-table--cell--PaddingRight: 0; }
  .pf-m-grid.pf-c-table .pf-c-table {
    --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table-tr--responsive--nested-table--PaddingTop);
    --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--nested-table--PaddingRight);
    --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table-tr--responsive--nested-table--PaddingBottom);
    --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--nested-table--PaddingLeft);
    border: 0; }
    .pf-m-grid.pf-c-table .pf-c-table tr:not(.pf-c-table__expandable-row) + tr:not(.pf-c-table__expandable-row) {
      --pf-c-table-tr--responsive--PaddingTop: 0; }
  .pf-m-grid.pf-c-table .pf-c-table__compound-expansion-toggle {
    --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
    --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
    --pf-c-table__compound-expansion-toggle__button--after--Top: 100%; }
  .pf-m-grid.pf-c-table tbody {
    position: relative; }
    .pf-m-grid.pf-c-table tbody::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      content: "";
      border: 0;
      border-left: var(--pf-c-table--tbody--after--BorderLeftWidth) solid var(--pf-c-table--tbody--after--BorderColor); }
    .pf-m-grid.pf-c-table tbody.pf-m-expanded {
      --pf-c-table--tbody--after--BorderLeftWidth: var(--pf-c-table--tbody--after--border-width--base); }
      .pf-m-grid.pf-c-table tbody.pf-m-expanded tbody {
        --pf-c-table--tbody--after--BorderLeftWidth: 0; }
    .pf-m-grid.pf-c-table tbody > tr > :first-child:not(.pf-c-table__check)::after {
      --pf-c-table__expandable-row--after--BorderLeftWidth: 0;
      position: static;
      width: auto;
      background-color: transparent; }
  .pf-m-grid.pf-c-table .pf-c-table__expandable-row {
    --pf-c-table--cell--responsive--PaddingTop: 0;
    --pf-c-table--cell--responsive--PaddingRight: 0;
    --pf-c-table--cell--responsive--PaddingBottom: 0;
    --pf-c-table--cell--responsive--PaddingLeft: 0;
    --pf-c-table--cell--PaddingRight: 0;
    --pf-c-table--cell--PaddingLeft: 0;
    display: block;
    max-height: var(--pf-c-table__expandable-row--MaxHeight);
    overflow-y: auto;
    border-bottom: none;
    box-shadow: none; }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row > * {
      position: static;
      display: block; }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row.pf-m-expanded {
      border-top-color: var(--pf-c-table--BorderColor); }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row > :first-child:not(.pf-c-table__check)::after {
      content: none; }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row td.pf-m-no-padding .pf-c-table__expandable-row-content, .pf-m-grid.pf-c-table .pf-c-table__expandable-row th.pf-m-no-padding .pf-c-table__expandable-row-content {
      padding: 0; }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row:not(.pf-m-expanded) {
      display: none;
      visibility: hidden; }
    .pf-m-grid.pf-c-table .pf-c-table__expandable-row .pf-c-table__expandable-row-content {
      padding-right: var(--pf-c-table__expandable-row-content--responsive--PaddingRight);
      padding-left: var(--pf-c-table__expandable-row-content--responsive--PaddingLeft); }
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__toggle,
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__check,
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__favorite,
  .pf-m-grid.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__action {
    width: auto;
    padding: 0; }
  .pf-m-grid.pf-c-table .pf-c-table__toggle {
    grid-row-start: 20;
    grid-column: -1;
    justify-self: end;
    padding-right: 0; }
    .pf-m-grid.pf-c-table .pf-c-table__toggle::after {
      content: none; }
  .pf-m-grid.pf-c-table .pf-c-table__button {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft); }
  .pf-m-grid.pf-c-table .pf-c-table__check,
  .pf-m-grid.pf-c-table .pf-c-table__favorite,
  .pf-m-grid.pf-c-table .pf-c-table__action {
    grid-row-start: 1;
    grid-column-start: 2; }
  .pf-m-grid.pf-c-table .pf-c-table__check {
    margin-top: var(--pf-c-table__check--responsive--MarginTop);
    margin-left: var(--pf-c-table__check--responsive--MarginLeft); }
    .pf-m-grid.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite {
      margin-left: var(--pf-c-table--m-grid__check--favorite--MarginLeft); }
      .pf-m-grid.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite ~ .pf-c-table__action {
        margin-left: var(--pf-c-table--m-grid__check--favorite--action--MarginLeft); }
    .pf-m-grid.pf-c-table .pf-c-table__check ~ .pf-c-table__action {
      margin-left: var(--pf-c-table__action--responsive--MarginLeft); }
  .pf-m-grid.pf-c-table .pf-c-table__favorite {
    margin-top: var(--pf-c-table--m-grid__favorite--MarginTop); }
    .pf-m-grid.pf-c-table .pf-c-table__favorite ~ .pf-c-table__action {
      margin-left: var(--pf-c-table--m-grid__favorite--action--MarginLeft); }
  .pf-m-grid.pf-c-table .pf-c-table__action {
    margin-top: var(--pf-c-table--m-grid__action--MarginTop);
    text-align: right; }
    @media screen and (max-width: 576px) {
      .pf-m-grid.pf-c-table .pf-c-table__action {
        grid-row-start: 1;
        grid-column-start: 2;
        margin-left: 0; } }
  .pf-m-grid.pf-c-table .pf-c-table__inline-edit-action {
    grid-column: 2;
    grid-row: 2; }
  .pf-m-grid.pf-c-table .pf-c-table__toggle-icon {
    transition: var(--pf-c-table__toggle__icon--Transition); }
    .pf-c-button.pf-m-expanded > .pf-m-grid.pf-c-table .pf-c-table__toggle-icon {
      transform: rotate(var(--pf-c-table__toggle--m-expanded__icon--Rotate)); }
  .pf-m-grid.pf-c-table .pf-m-nowrap {
    --pf-c-table--cell--Overflow: auto; }
  .pf-m-grid.pf-c-table .pf-m-fit-content {
    width: auto;
    white-space: normal; }
  .pf-m-grid.pf-c-table .pf-m-truncate {
    --pf-c-table--cell--MaxWidth: 100%; }
  .pf-m-grid.pf-c-table [class*="pf-m-width"] {
    --pf-c-table--cell--Width: auto; }

@media screen and (max-width: 768px) {
  .pf-m-grid-md.pf-c-table {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft);
    --pf-c-table__favorite--c-button--MarginTop: auto;
    --pf-c-table__favorite--c-button--MarginRight: auto;
    --pf-c-table__favorite--c-button--MarginBottom: auto;
    --pf-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none; }
    .pf-m-grid-md.pf-c-table tr > * {
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: visible;
      text-overflow: clip;
      white-space: normal; }
    .pf-m-grid-md.pf-c-table .pf-c-table__text {
      position: relative;
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: var(--pf-c-table--cell--Overflow);
      text-overflow: var(--pf-c-table--cell--TextOverflow);
      white-space: var(--pf-c-table--cell--WhiteSpace); }
    .pf-m-grid-md.pf-c-table thead {
      display: none;
      visibility: hidden; }
    .pf-m-grid-md.pf-c-table tbody {
      display: block; }
      .pf-m-grid-md.pf-c-table tbody:first-of-type {
        border-top: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-md.pf-c-table table.pf-m-compact > tbody {
      border-top: 0; }
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) {
      border-bottom: var(--pf-c-table-tr--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-md.pf-c-table tr:last-child,
    .pf-m-grid-md.pf-c-table tbody:last-of-type:not(:only-of-type) > tr {
      border-bottom-width: var(--pf-c-table-tr--responsive--last-child--BorderBottomWidth); }
    .pf-m-grid-md.pf-c-table tbody.pf-m-expanded {
      border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor); }
      .pf-m-grid-md.pf-c-table tbody.pf-m-expanded tr:not(.pf-c-table__expandable-row) {
        border-bottom: 0; }
      .pf-m-grid-md.pf-c-table tbody.pf-m-expanded:not(:last-of-type) {
        border-bottom: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) {
      display: grid;
      grid-template-columns: 1fr;
      height: auto;
      grid-auto-columns: max-content;
      grid-column-gap: var(--pf-c-table-tr--responsive--GridColumnGap);
      padding: var(--pf-c-table-tr--responsive--PaddingTop) var(--pf-c-table-tr--responsive--PaddingRight) var(--pf-c-table-tr--responsive--PaddingBottom) var(--pf-c-table-tr--responsive--PaddingLeft); }
      .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) > * {
        padding: var(--pf-c-table--cell--responsive--PaddingTop) var(--pf-c-table--cell--responsive--PaddingRight) var(--pf-c-table--cell--responsive--PaddingBottom) var(--pf-c-table--cell--responsive--PaddingLeft); }
        .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) > *:first-child {
          --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--cell--first-child--responsive--PaddingTop); }
    .pf-m-grid-md.pf-c-table.pf-m-compact {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table--m-compact-tr--responsive--PaddingTop);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr--responsive--PaddingBottom);
      --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--m-compact-tr-td--responsive--PaddingTop);
      --pf-c-table--cell--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr-td--responsive--PaddingBottom);
      --pf-c-table__check--input--MarginTop: 0; }
      .pf-m-grid-md.pf-c-table.pf-m-compact .pf-c-table__action {
        margin-top: var(--pf-c-table--m-compact__action--responsive--MarginTop);
        margin-bottom: var(--pf-c-table--m-compact__action--responsive--MarginTop); }
      .pf-m-grid-md.pf-c-table.pf-m-compact .pf-c-table__toggle .pf-c-button {
        margin-bottom: var(--pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom); }
    .pf-m-grid-md.pf-c-table .pf-c-table__icon > * {
      text-align: left; }
    .pf-m-grid-md.pf-c-table [data-label] {
      --pf-c-table--cell--hidden-visible--Display: var(--pf-c-table--m-grid--cell--hidden-visible--Display);
      grid-column: 1;
      grid-column-gap: var(--pf-c-table-td--responsive--GridColumnGap);
      grid-template-columns: 1fr minmax(0, 1.5fr);
      align-items: start; }
      .pf-m-grid-md.pf-c-table [data-label] > * {
        grid-column: 2; }
      .pf-m-grid-md.pf-c-table [data-label]::before {
        font-weight: bold;
        text-align: left;
        content: attr(data-label); }
    .pf-m-grid-md.pf-c-table tr > *:first-child {
      --pf-c-table--cell--PaddingLeft: 0; }
    .pf-m-grid-md.pf-c-table tr > *:last-child {
      --pf-c-table--cell--PaddingRight: 0; }
    .pf-m-grid-md.pf-c-table .pf-c-table {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table-tr--responsive--nested-table--PaddingTop);
      --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--nested-table--PaddingRight);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table-tr--responsive--nested-table--PaddingBottom);
      --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--nested-table--PaddingLeft);
      border: 0; }
      .pf-m-grid-md.pf-c-table .pf-c-table tr:not(.pf-c-table__expandable-row) + tr:not(.pf-c-table__expandable-row) {
        --pf-c-table-tr--responsive--PaddingTop: 0; }
    .pf-m-grid-md.pf-c-table .pf-c-table__compound-expansion-toggle {
      --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--after--Top: 100%; }
    .pf-m-grid-md.pf-c-table tbody {
      position: relative; }
      .pf-m-grid-md.pf-c-table tbody::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: "";
        border: 0;
        border-left: var(--pf-c-table--tbody--after--BorderLeftWidth) solid var(--pf-c-table--tbody--after--BorderColor); }
      .pf-m-grid-md.pf-c-table tbody.pf-m-expanded {
        --pf-c-table--tbody--after--BorderLeftWidth: var(--pf-c-table--tbody--after--border-width--base); }
        .pf-m-grid-md.pf-c-table tbody.pf-m-expanded tbody {
          --pf-c-table--tbody--after--BorderLeftWidth: 0; }
      .pf-m-grid-md.pf-c-table tbody > tr > :first-child:not(.pf-c-table__check)::after {
        --pf-c-table__expandable-row--after--BorderLeftWidth: 0;
        position: static;
        width: auto;
        background-color: transparent; }
    .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row {
      --pf-c-table--cell--responsive--PaddingTop: 0;
      --pf-c-table--cell--responsive--PaddingRight: 0;
      --pf-c-table--cell--responsive--PaddingBottom: 0;
      --pf-c-table--cell--responsive--PaddingLeft: 0;
      --pf-c-table--cell--PaddingRight: 0;
      --pf-c-table--cell--PaddingLeft: 0;
      display: block;
      max-height: var(--pf-c-table__expandable-row--MaxHeight);
      overflow-y: auto;
      border-bottom: none;
      box-shadow: none; }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row > * {
        position: static;
        display: block; }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row.pf-m-expanded {
        border-top-color: var(--pf-c-table--BorderColor); }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row > :first-child:not(.pf-c-table__check)::after {
        content: none; }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row td.pf-m-no-padding .pf-c-table__expandable-row-content, .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row th.pf-m-no-padding .pf-c-table__expandable-row-content {
        padding: 0; }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row:not(.pf-m-expanded) {
        display: none;
        visibility: hidden; }
      .pf-m-grid-md.pf-c-table .pf-c-table__expandable-row .pf-c-table__expandable-row-content {
        padding-right: var(--pf-c-table__expandable-row-content--responsive--PaddingRight);
        padding-left: var(--pf-c-table__expandable-row-content--responsive--PaddingLeft); }
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__toggle,
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__check,
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__favorite,
    .pf-m-grid-md.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__action {
      width: auto;
      padding: 0; }
    .pf-m-grid-md.pf-c-table .pf-c-table__toggle {
      grid-row-start: 20;
      grid-column: -1;
      justify-self: end;
      padding-right: 0; }
      .pf-m-grid-md.pf-c-table .pf-c-table__toggle::after {
        content: none; }
    .pf-m-grid-md.pf-c-table .pf-c-table__button {
      --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
      --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
      --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
      --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft); }
    .pf-m-grid-md.pf-c-table .pf-c-table__check,
    .pf-m-grid-md.pf-c-table .pf-c-table__favorite,
    .pf-m-grid-md.pf-c-table .pf-c-table__action {
      grid-row-start: 1;
      grid-column-start: 2; }
    .pf-m-grid-md.pf-c-table .pf-c-table__check {
      margin-top: var(--pf-c-table__check--responsive--MarginTop);
      margin-left: var(--pf-c-table__check--responsive--MarginLeft); }
      .pf-m-grid-md.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite {
        margin-left: var(--pf-c-table--m-grid__check--favorite--MarginLeft); }
        .pf-m-grid-md.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite ~ .pf-c-table__action {
          margin-left: var(--pf-c-table--m-grid__check--favorite--action--MarginLeft); }
      .pf-m-grid-md.pf-c-table .pf-c-table__check ~ .pf-c-table__action {
        margin-left: var(--pf-c-table__action--responsive--MarginLeft); }
    .pf-m-grid-md.pf-c-table .pf-c-table__favorite {
      margin-top: var(--pf-c-table--m-grid__favorite--MarginTop); }
      .pf-m-grid-md.pf-c-table .pf-c-table__favorite ~ .pf-c-table__action {
        margin-left: var(--pf-c-table--m-grid__favorite--action--MarginLeft); }
    .pf-m-grid-md.pf-c-table .pf-c-table__action {
      margin-top: var(--pf-c-table--m-grid__action--MarginTop);
      text-align: right; } }
    @media screen and (max-width: 768px) and (max-width: 576px) {
      .pf-m-grid-md.pf-c-table .pf-c-table__action {
        grid-row-start: 1;
        grid-column-start: 2;
        margin-left: 0; } }

@media screen and (max-width: 768px) {
    .pf-m-grid-md.pf-c-table .pf-c-table__inline-edit-action {
      grid-column: 2;
      grid-row: 2; }
    .pf-m-grid-md.pf-c-table .pf-c-table__toggle-icon {
      transition: var(--pf-c-table__toggle__icon--Transition); }
      .pf-c-button.pf-m-expanded > .pf-m-grid-md.pf-c-table .pf-c-table__toggle-icon {
        transform: rotate(var(--pf-c-table__toggle--m-expanded__icon--Rotate)); }
    .pf-m-grid-md.pf-c-table .pf-m-nowrap {
      --pf-c-table--cell--Overflow: auto; }
    .pf-m-grid-md.pf-c-table .pf-m-fit-content {
      width: auto;
      white-space: normal; }
    .pf-m-grid-md.pf-c-table .pf-m-truncate {
      --pf-c-table--cell--MaxWidth: 100%; }
    .pf-m-grid-md.pf-c-table [class*="pf-m-width"] {
      --pf-c-table--cell--Width: auto; } }

@media screen and (max-width: 992px) {
  .pf-m-grid-lg.pf-c-table {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft);
    --pf-c-table__favorite--c-button--MarginTop: auto;
    --pf-c-table__favorite--c-button--MarginRight: auto;
    --pf-c-table__favorite--c-button--MarginBottom: auto;
    --pf-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none; }
    .pf-m-grid-lg.pf-c-table tr > * {
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: visible;
      text-overflow: clip;
      white-space: normal; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__text {
      position: relative;
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: var(--pf-c-table--cell--Overflow);
      text-overflow: var(--pf-c-table--cell--TextOverflow);
      white-space: var(--pf-c-table--cell--WhiteSpace); }
    .pf-m-grid-lg.pf-c-table thead {
      display: none;
      visibility: hidden; }
    .pf-m-grid-lg.pf-c-table tbody {
      display: block; }
      .pf-m-grid-lg.pf-c-table tbody:first-of-type {
        border-top: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-lg.pf-c-table table.pf-m-compact > tbody {
      border-top: 0; }
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) {
      border-bottom: var(--pf-c-table-tr--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-lg.pf-c-table tr:last-child,
    .pf-m-grid-lg.pf-c-table tbody:last-of-type:not(:only-of-type) > tr {
      border-bottom-width: var(--pf-c-table-tr--responsive--last-child--BorderBottomWidth); }
    .pf-m-grid-lg.pf-c-table tbody.pf-m-expanded {
      border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor); }
      .pf-m-grid-lg.pf-c-table tbody.pf-m-expanded tr:not(.pf-c-table__expandable-row) {
        border-bottom: 0; }
      .pf-m-grid-lg.pf-c-table tbody.pf-m-expanded:not(:last-of-type) {
        border-bottom: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) {
      display: grid;
      grid-template-columns: 1fr;
      height: auto;
      grid-auto-columns: max-content;
      grid-column-gap: var(--pf-c-table-tr--responsive--GridColumnGap);
      padding: var(--pf-c-table-tr--responsive--PaddingTop) var(--pf-c-table-tr--responsive--PaddingRight) var(--pf-c-table-tr--responsive--PaddingBottom) var(--pf-c-table-tr--responsive--PaddingLeft); }
      .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) > * {
        padding: var(--pf-c-table--cell--responsive--PaddingTop) var(--pf-c-table--cell--responsive--PaddingRight) var(--pf-c-table--cell--responsive--PaddingBottom) var(--pf-c-table--cell--responsive--PaddingLeft); }
        .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) > *:first-child {
          --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--cell--first-child--responsive--PaddingTop); }
    .pf-m-grid-lg.pf-c-table.pf-m-compact {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table--m-compact-tr--responsive--PaddingTop);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr--responsive--PaddingBottom);
      --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--m-compact-tr-td--responsive--PaddingTop);
      --pf-c-table--cell--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr-td--responsive--PaddingBottom);
      --pf-c-table__check--input--MarginTop: 0; }
      .pf-m-grid-lg.pf-c-table.pf-m-compact .pf-c-table__action {
        margin-top: var(--pf-c-table--m-compact__action--responsive--MarginTop);
        margin-bottom: var(--pf-c-table--m-compact__action--responsive--MarginTop); }
      .pf-m-grid-lg.pf-c-table.pf-m-compact .pf-c-table__toggle .pf-c-button {
        margin-bottom: var(--pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom); }
    .pf-m-grid-lg.pf-c-table .pf-c-table__icon > * {
      text-align: left; }
    .pf-m-grid-lg.pf-c-table [data-label] {
      --pf-c-table--cell--hidden-visible--Display: var(--pf-c-table--m-grid--cell--hidden-visible--Display);
      grid-column: 1;
      grid-column-gap: var(--pf-c-table-td--responsive--GridColumnGap);
      grid-template-columns: 1fr minmax(0, 1.5fr);
      align-items: start; }
      .pf-m-grid-lg.pf-c-table [data-label] > * {
        grid-column: 2; }
      .pf-m-grid-lg.pf-c-table [data-label]::before {
        font-weight: bold;
        text-align: left;
        content: attr(data-label); }
    .pf-m-grid-lg.pf-c-table tr > *:first-child {
      --pf-c-table--cell--PaddingLeft: 0; }
    .pf-m-grid-lg.pf-c-table tr > *:last-child {
      --pf-c-table--cell--PaddingRight: 0; }
    .pf-m-grid-lg.pf-c-table .pf-c-table {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table-tr--responsive--nested-table--PaddingTop);
      --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--nested-table--PaddingRight);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table-tr--responsive--nested-table--PaddingBottom);
      --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--nested-table--PaddingLeft);
      border: 0; }
      .pf-m-grid-lg.pf-c-table .pf-c-table tr:not(.pf-c-table__expandable-row) + tr:not(.pf-c-table__expandable-row) {
        --pf-c-table-tr--responsive--PaddingTop: 0; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__compound-expansion-toggle {
      --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--after--Top: 100%; }
    .pf-m-grid-lg.pf-c-table tbody {
      position: relative; }
      .pf-m-grid-lg.pf-c-table tbody::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: "";
        border: 0;
        border-left: var(--pf-c-table--tbody--after--BorderLeftWidth) solid var(--pf-c-table--tbody--after--BorderColor); }
      .pf-m-grid-lg.pf-c-table tbody.pf-m-expanded {
        --pf-c-table--tbody--after--BorderLeftWidth: var(--pf-c-table--tbody--after--border-width--base); }
        .pf-m-grid-lg.pf-c-table tbody.pf-m-expanded tbody {
          --pf-c-table--tbody--after--BorderLeftWidth: 0; }
      .pf-m-grid-lg.pf-c-table tbody > tr > :first-child:not(.pf-c-table__check)::after {
        --pf-c-table__expandable-row--after--BorderLeftWidth: 0;
        position: static;
        width: auto;
        background-color: transparent; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row {
      --pf-c-table--cell--responsive--PaddingTop: 0;
      --pf-c-table--cell--responsive--PaddingRight: 0;
      --pf-c-table--cell--responsive--PaddingBottom: 0;
      --pf-c-table--cell--responsive--PaddingLeft: 0;
      --pf-c-table--cell--PaddingRight: 0;
      --pf-c-table--cell--PaddingLeft: 0;
      display: block;
      max-height: var(--pf-c-table__expandable-row--MaxHeight);
      overflow-y: auto;
      border-bottom: none;
      box-shadow: none; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row > * {
        position: static;
        display: block; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row.pf-m-expanded {
        border-top-color: var(--pf-c-table--BorderColor); }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row > :first-child:not(.pf-c-table__check)::after {
        content: none; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row td.pf-m-no-padding .pf-c-table__expandable-row-content, .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row th.pf-m-no-padding .pf-c-table__expandable-row-content {
        padding: 0; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row:not(.pf-m-expanded) {
        display: none;
        visibility: hidden; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__expandable-row .pf-c-table__expandable-row-content {
        padding-right: var(--pf-c-table__expandable-row-content--responsive--PaddingRight);
        padding-left: var(--pf-c-table__expandable-row-content--responsive--PaddingLeft); }
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__toggle,
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__check,
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__favorite,
    .pf-m-grid-lg.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__action {
      width: auto;
      padding: 0; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__toggle {
      grid-row-start: 20;
      grid-column: -1;
      justify-self: end;
      padding-right: 0; }
      .pf-m-grid-lg.pf-c-table .pf-c-table__toggle::after {
        content: none; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__button {
      --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
      --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
      --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
      --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft); }
    .pf-m-grid-lg.pf-c-table .pf-c-table__check,
    .pf-m-grid-lg.pf-c-table .pf-c-table__favorite,
    .pf-m-grid-lg.pf-c-table .pf-c-table__action {
      grid-row-start: 1;
      grid-column-start: 2; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__check {
      margin-top: var(--pf-c-table__check--responsive--MarginTop);
      margin-left: var(--pf-c-table__check--responsive--MarginLeft); }
      .pf-m-grid-lg.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite {
        margin-left: var(--pf-c-table--m-grid__check--favorite--MarginLeft); }
        .pf-m-grid-lg.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite ~ .pf-c-table__action {
          margin-left: var(--pf-c-table--m-grid__check--favorite--action--MarginLeft); }
      .pf-m-grid-lg.pf-c-table .pf-c-table__check ~ .pf-c-table__action {
        margin-left: var(--pf-c-table__action--responsive--MarginLeft); }
    .pf-m-grid-lg.pf-c-table .pf-c-table__favorite {
      margin-top: var(--pf-c-table--m-grid__favorite--MarginTop); }
      .pf-m-grid-lg.pf-c-table .pf-c-table__favorite ~ .pf-c-table__action {
        margin-left: var(--pf-c-table--m-grid__favorite--action--MarginLeft); }
    .pf-m-grid-lg.pf-c-table .pf-c-table__action {
      margin-top: var(--pf-c-table--m-grid__action--MarginTop);
      text-align: right; } }
    @media screen and (max-width: 992px) and (max-width: 576px) {
      .pf-m-grid-lg.pf-c-table .pf-c-table__action {
        grid-row-start: 1;
        grid-column-start: 2;
        margin-left: 0; } }

@media screen and (max-width: 992px) {
    .pf-m-grid-lg.pf-c-table .pf-c-table__inline-edit-action {
      grid-column: 2;
      grid-row: 2; }
    .pf-m-grid-lg.pf-c-table .pf-c-table__toggle-icon {
      transition: var(--pf-c-table__toggle__icon--Transition); }
      .pf-c-button.pf-m-expanded > .pf-m-grid-lg.pf-c-table .pf-c-table__toggle-icon {
        transform: rotate(var(--pf-c-table__toggle--m-expanded__icon--Rotate)); }
    .pf-m-grid-lg.pf-c-table .pf-m-nowrap {
      --pf-c-table--cell--Overflow: auto; }
    .pf-m-grid-lg.pf-c-table .pf-m-fit-content {
      width: auto;
      white-space: normal; }
    .pf-m-grid-lg.pf-c-table .pf-m-truncate {
      --pf-c-table--cell--MaxWidth: 100%; }
    .pf-m-grid-lg.pf-c-table [class*="pf-m-width"] {
      --pf-c-table--cell--Width: auto; } }

@media screen and (max-width: 1200px) {
  .pf-m-grid-xl.pf-c-table {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft);
    --pf-c-table__favorite--c-button--MarginTop: auto;
    --pf-c-table__favorite--c-button--MarginRight: auto;
    --pf-c-table__favorite--c-button--MarginBottom: auto;
    --pf-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none; }
    .pf-m-grid-xl.pf-c-table tr > * {
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: visible;
      text-overflow: clip;
      white-space: normal; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__text {
      position: relative;
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: var(--pf-c-table--cell--Overflow);
      text-overflow: var(--pf-c-table--cell--TextOverflow);
      white-space: var(--pf-c-table--cell--WhiteSpace); }
    .pf-m-grid-xl.pf-c-table thead {
      display: none;
      visibility: hidden; }
    .pf-m-grid-xl.pf-c-table tbody {
      display: block; }
      .pf-m-grid-xl.pf-c-table tbody:first-of-type {
        border-top: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-xl.pf-c-table table.pf-m-compact > tbody {
      border-top: 0; }
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) {
      border-bottom: var(--pf-c-table-tr--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-xl.pf-c-table tr:last-child,
    .pf-m-grid-xl.pf-c-table tbody:last-of-type:not(:only-of-type) > tr {
      border-bottom-width: var(--pf-c-table-tr--responsive--last-child--BorderBottomWidth); }
    .pf-m-grid-xl.pf-c-table tbody.pf-m-expanded {
      border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor); }
      .pf-m-grid-xl.pf-c-table tbody.pf-m-expanded tr:not(.pf-c-table__expandable-row) {
        border-bottom: 0; }
      .pf-m-grid-xl.pf-c-table tbody.pf-m-expanded:not(:last-of-type) {
        border-bottom: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) {
      display: grid;
      grid-template-columns: 1fr;
      height: auto;
      grid-auto-columns: max-content;
      grid-column-gap: var(--pf-c-table-tr--responsive--GridColumnGap);
      padding: var(--pf-c-table-tr--responsive--PaddingTop) var(--pf-c-table-tr--responsive--PaddingRight) var(--pf-c-table-tr--responsive--PaddingBottom) var(--pf-c-table-tr--responsive--PaddingLeft); }
      .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) > * {
        padding: var(--pf-c-table--cell--responsive--PaddingTop) var(--pf-c-table--cell--responsive--PaddingRight) var(--pf-c-table--cell--responsive--PaddingBottom) var(--pf-c-table--cell--responsive--PaddingLeft); }
        .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) > *:first-child {
          --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--cell--first-child--responsive--PaddingTop); }
    .pf-m-grid-xl.pf-c-table.pf-m-compact {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table--m-compact-tr--responsive--PaddingTop);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr--responsive--PaddingBottom);
      --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--m-compact-tr-td--responsive--PaddingTop);
      --pf-c-table--cell--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr-td--responsive--PaddingBottom);
      --pf-c-table__check--input--MarginTop: 0; }
      .pf-m-grid-xl.pf-c-table.pf-m-compact .pf-c-table__action {
        margin-top: var(--pf-c-table--m-compact__action--responsive--MarginTop);
        margin-bottom: var(--pf-c-table--m-compact__action--responsive--MarginTop); }
      .pf-m-grid-xl.pf-c-table.pf-m-compact .pf-c-table__toggle .pf-c-button {
        margin-bottom: var(--pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom); }
    .pf-m-grid-xl.pf-c-table .pf-c-table__icon > * {
      text-align: left; }
    .pf-m-grid-xl.pf-c-table [data-label] {
      --pf-c-table--cell--hidden-visible--Display: var(--pf-c-table--m-grid--cell--hidden-visible--Display);
      grid-column: 1;
      grid-column-gap: var(--pf-c-table-td--responsive--GridColumnGap);
      grid-template-columns: 1fr minmax(0, 1.5fr);
      align-items: start; }
      .pf-m-grid-xl.pf-c-table [data-label] > * {
        grid-column: 2; }
      .pf-m-grid-xl.pf-c-table [data-label]::before {
        font-weight: bold;
        text-align: left;
        content: attr(data-label); }
    .pf-m-grid-xl.pf-c-table tr > *:first-child {
      --pf-c-table--cell--PaddingLeft: 0; }
    .pf-m-grid-xl.pf-c-table tr > *:last-child {
      --pf-c-table--cell--PaddingRight: 0; }
    .pf-m-grid-xl.pf-c-table .pf-c-table {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table-tr--responsive--nested-table--PaddingTop);
      --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--nested-table--PaddingRight);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table-tr--responsive--nested-table--PaddingBottom);
      --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--nested-table--PaddingLeft);
      border: 0; }
      .pf-m-grid-xl.pf-c-table .pf-c-table tr:not(.pf-c-table__expandable-row) + tr:not(.pf-c-table__expandable-row) {
        --pf-c-table-tr--responsive--PaddingTop: 0; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__compound-expansion-toggle {
      --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--after--Top: 100%; }
    .pf-m-grid-xl.pf-c-table tbody {
      position: relative; }
      .pf-m-grid-xl.pf-c-table tbody::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: "";
        border: 0;
        border-left: var(--pf-c-table--tbody--after--BorderLeftWidth) solid var(--pf-c-table--tbody--after--BorderColor); }
      .pf-m-grid-xl.pf-c-table tbody.pf-m-expanded {
        --pf-c-table--tbody--after--BorderLeftWidth: var(--pf-c-table--tbody--after--border-width--base); }
        .pf-m-grid-xl.pf-c-table tbody.pf-m-expanded tbody {
          --pf-c-table--tbody--after--BorderLeftWidth: 0; }
      .pf-m-grid-xl.pf-c-table tbody > tr > :first-child:not(.pf-c-table__check)::after {
        --pf-c-table__expandable-row--after--BorderLeftWidth: 0;
        position: static;
        width: auto;
        background-color: transparent; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row {
      --pf-c-table--cell--responsive--PaddingTop: 0;
      --pf-c-table--cell--responsive--PaddingRight: 0;
      --pf-c-table--cell--responsive--PaddingBottom: 0;
      --pf-c-table--cell--responsive--PaddingLeft: 0;
      --pf-c-table--cell--PaddingRight: 0;
      --pf-c-table--cell--PaddingLeft: 0;
      display: block;
      max-height: var(--pf-c-table__expandable-row--MaxHeight);
      overflow-y: auto;
      border-bottom: none;
      box-shadow: none; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row > * {
        position: static;
        display: block; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row.pf-m-expanded {
        border-top-color: var(--pf-c-table--BorderColor); }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row > :first-child:not(.pf-c-table__check)::after {
        content: none; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row td.pf-m-no-padding .pf-c-table__expandable-row-content, .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row th.pf-m-no-padding .pf-c-table__expandable-row-content {
        padding: 0; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row:not(.pf-m-expanded) {
        display: none;
        visibility: hidden; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__expandable-row .pf-c-table__expandable-row-content {
        padding-right: var(--pf-c-table__expandable-row-content--responsive--PaddingRight);
        padding-left: var(--pf-c-table__expandable-row-content--responsive--PaddingLeft); }
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__toggle,
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__check,
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__favorite,
    .pf-m-grid-xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__action {
      width: auto;
      padding: 0; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__toggle {
      grid-row-start: 20;
      grid-column: -1;
      justify-self: end;
      padding-right: 0; }
      .pf-m-grid-xl.pf-c-table .pf-c-table__toggle::after {
        content: none; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__button {
      --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
      --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
      --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
      --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft); }
    .pf-m-grid-xl.pf-c-table .pf-c-table__check,
    .pf-m-grid-xl.pf-c-table .pf-c-table__favorite,
    .pf-m-grid-xl.pf-c-table .pf-c-table__action {
      grid-row-start: 1;
      grid-column-start: 2; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__check {
      margin-top: var(--pf-c-table__check--responsive--MarginTop);
      margin-left: var(--pf-c-table__check--responsive--MarginLeft); }
      .pf-m-grid-xl.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite {
        margin-left: var(--pf-c-table--m-grid__check--favorite--MarginLeft); }
        .pf-m-grid-xl.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite ~ .pf-c-table__action {
          margin-left: var(--pf-c-table--m-grid__check--favorite--action--MarginLeft); }
      .pf-m-grid-xl.pf-c-table .pf-c-table__check ~ .pf-c-table__action {
        margin-left: var(--pf-c-table__action--responsive--MarginLeft); }
    .pf-m-grid-xl.pf-c-table .pf-c-table__favorite {
      margin-top: var(--pf-c-table--m-grid__favorite--MarginTop); }
      .pf-m-grid-xl.pf-c-table .pf-c-table__favorite ~ .pf-c-table__action {
        margin-left: var(--pf-c-table--m-grid__favorite--action--MarginLeft); }
    .pf-m-grid-xl.pf-c-table .pf-c-table__action {
      margin-top: var(--pf-c-table--m-grid__action--MarginTop);
      text-align: right; } }
    @media screen and (max-width: 1200px) and (max-width: 576px) {
      .pf-m-grid-xl.pf-c-table .pf-c-table__action {
        grid-row-start: 1;
        grid-column-start: 2;
        margin-left: 0; } }

@media screen and (max-width: 1200px) {
    .pf-m-grid-xl.pf-c-table .pf-c-table__inline-edit-action {
      grid-column: 2;
      grid-row: 2; }
    .pf-m-grid-xl.pf-c-table .pf-c-table__toggle-icon {
      transition: var(--pf-c-table__toggle__icon--Transition); }
      .pf-c-button.pf-m-expanded > .pf-m-grid-xl.pf-c-table .pf-c-table__toggle-icon {
        transform: rotate(var(--pf-c-table__toggle--m-expanded__icon--Rotate)); }
    .pf-m-grid-xl.pf-c-table .pf-m-nowrap {
      --pf-c-table--cell--Overflow: auto; }
    .pf-m-grid-xl.pf-c-table .pf-m-fit-content {
      width: auto;
      white-space: normal; }
    .pf-m-grid-xl.pf-c-table .pf-m-truncate {
      --pf-c-table--cell--MaxWidth: 100%; }
    .pf-m-grid-xl.pf-c-table [class*="pf-m-width"] {
      --pf-c-table--cell--Width: auto; } }

@media screen and (max-width: 1450px) {
  .pf-m-grid-2xl.pf-c-table {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
    --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
    --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft);
    --pf-c-table__favorite--c-button--MarginTop: auto;
    --pf-c-table__favorite--c-button--MarginRight: auto;
    --pf-c-table__favorite--c-button--MarginBottom: auto;
    --pf-c-table__favorite--c-button--MarginLeft: auto;
    display: grid;
    border: none; }
    .pf-m-grid-2xl.pf-c-table tr > * {
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: visible;
      text-overflow: clip;
      white-space: normal; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__text {
      position: relative;
      width: auto;
      min-width: 0;
      max-width: none;
      overflow: var(--pf-c-table--cell--Overflow);
      text-overflow: var(--pf-c-table--cell--TextOverflow);
      white-space: var(--pf-c-table--cell--WhiteSpace); }
    .pf-m-grid-2xl.pf-c-table thead {
      display: none;
      visibility: hidden; }
    .pf-m-grid-2xl.pf-c-table tbody {
      display: block; }
      .pf-m-grid-2xl.pf-c-table tbody:first-of-type {
        border-top: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-2xl.pf-c-table table.pf-m-compact > tbody {
      border-top: 0; }
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) {
      border-bottom: var(--pf-c-table-tr--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-2xl.pf-c-table tr:last-child,
    .pf-m-grid-2xl.pf-c-table tbody:last-of-type:not(:only-of-type) > tr {
      border-bottom-width: var(--pf-c-table-tr--responsive--last-child--BorderBottomWidth); }
    .pf-m-grid-2xl.pf-c-table tbody.pf-m-expanded {
      border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor); }
      .pf-m-grid-2xl.pf-c-table tbody.pf-m-expanded tr:not(.pf-c-table__expandable-row) {
        border-bottom: 0; }
      .pf-m-grid-2xl.pf-c-table tbody.pf-m-expanded:not(:last-of-type) {
        border-bottom: var(--pf-c-table--tbody--responsive--border-width--base) solid var(--pf-c-table--responsive--BorderColor); }
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) {
      display: grid;
      grid-template-columns: 1fr;
      height: auto;
      grid-auto-columns: max-content;
      grid-column-gap: var(--pf-c-table-tr--responsive--GridColumnGap);
      padding: var(--pf-c-table-tr--responsive--PaddingTop) var(--pf-c-table-tr--responsive--PaddingRight) var(--pf-c-table-tr--responsive--PaddingBottom) var(--pf-c-table-tr--responsive--PaddingLeft); }
      .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) > * {
        padding: var(--pf-c-table--cell--responsive--PaddingTop) var(--pf-c-table--cell--responsive--PaddingRight) var(--pf-c-table--cell--responsive--PaddingBottom) var(--pf-c-table--cell--responsive--PaddingLeft); }
        .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) > *:first-child {
          --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--cell--first-child--responsive--PaddingTop); }
    .pf-m-grid-2xl.pf-c-table.pf-m-compact {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table--m-compact-tr--responsive--PaddingTop);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr--responsive--PaddingBottom);
      --pf-c-table--cell--responsive--PaddingTop: var(--pf-c-table--m-compact-tr-td--responsive--PaddingTop);
      --pf-c-table--cell--responsive--PaddingBottom: var(--pf-c-table--m-compact-tr-td--responsive--PaddingBottom);
      --pf-c-table__check--input--MarginTop: 0; }
      .pf-m-grid-2xl.pf-c-table.pf-m-compact .pf-c-table__action {
        margin-top: var(--pf-c-table--m-compact__action--responsive--MarginTop);
        margin-bottom: var(--pf-c-table--m-compact__action--responsive--MarginTop); }
      .pf-m-grid-2xl.pf-c-table.pf-m-compact .pf-c-table__toggle .pf-c-button {
        margin-bottom: var(--pf-c-table--m-compact__toggle--c-button--responsive--MarginBottom); }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__icon > * {
      text-align: left; }
    .pf-m-grid-2xl.pf-c-table [data-label] {
      --pf-c-table--cell--hidden-visible--Display: var(--pf-c-table--m-grid--cell--hidden-visible--Display);
      grid-column: 1;
      grid-column-gap: var(--pf-c-table-td--responsive--GridColumnGap);
      grid-template-columns: 1fr minmax(0, 1.5fr);
      align-items: start; }
      .pf-m-grid-2xl.pf-c-table [data-label] > * {
        grid-column: 2; }
      .pf-m-grid-2xl.pf-c-table [data-label]::before {
        font-weight: bold;
        text-align: left;
        content: attr(data-label); }
    .pf-m-grid-2xl.pf-c-table tr > *:first-child {
      --pf-c-table--cell--PaddingLeft: 0; }
    .pf-m-grid-2xl.pf-c-table tr > *:last-child {
      --pf-c-table--cell--PaddingRight: 0; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table {
      --pf-c-table-tr--responsive--PaddingTop: var(--pf-c-table-tr--responsive--nested-table--PaddingTop);
      --pf-c-table-tr--responsive--PaddingRight: var(--pf-c-table-tr--responsive--nested-table--PaddingRight);
      --pf-c-table-tr--responsive--PaddingBottom: var(--pf-c-table-tr--responsive--nested-table--PaddingBottom);
      --pf-c-table-tr--responsive--PaddingLeft: var(--pf-c-table-tr--responsive--nested-table--PaddingLeft);
      border: 0; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table tr:not(.pf-c-table__expandable-row) + tr:not(.pf-c-table__expandable-row) {
        --pf-c-table-tr--responsive--PaddingTop: 0; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__compound-expansion-toggle {
      --pf-c-table__compound-expansion-toggle__button--before--BorderRightWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--before--BorderLeftWidth: 0;
      --pf-c-table__compound-expansion-toggle__button--after--Top: 100%; }
    .pf-m-grid-2xl.pf-c-table tbody {
      position: relative; }
      .pf-m-grid-2xl.pf-c-table tbody::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: "";
        border: 0;
        border-left: var(--pf-c-table--tbody--after--BorderLeftWidth) solid var(--pf-c-table--tbody--after--BorderColor); }
      .pf-m-grid-2xl.pf-c-table tbody.pf-m-expanded {
        --pf-c-table--tbody--after--BorderLeftWidth: var(--pf-c-table--tbody--after--border-width--base); }
        .pf-m-grid-2xl.pf-c-table tbody.pf-m-expanded tbody {
          --pf-c-table--tbody--after--BorderLeftWidth: 0; }
      .pf-m-grid-2xl.pf-c-table tbody > tr > :first-child:not(.pf-c-table__check)::after {
        --pf-c-table__expandable-row--after--BorderLeftWidth: 0;
        position: static;
        width: auto;
        background-color: transparent; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row {
      --pf-c-table--cell--responsive--PaddingTop: 0;
      --pf-c-table--cell--responsive--PaddingRight: 0;
      --pf-c-table--cell--responsive--PaddingBottom: 0;
      --pf-c-table--cell--responsive--PaddingLeft: 0;
      --pf-c-table--cell--PaddingRight: 0;
      --pf-c-table--cell--PaddingLeft: 0;
      display: block;
      max-height: var(--pf-c-table__expandable-row--MaxHeight);
      overflow-y: auto;
      border-bottom: none;
      box-shadow: none; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row > * {
        position: static;
        display: block; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row.pf-m-expanded {
        border-top-color: var(--pf-c-table--BorderColor); }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row > :first-child:not(.pf-c-table__check)::after {
        content: none; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row td.pf-m-no-padding .pf-c-table__expandable-row-content, .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row th.pf-m-no-padding .pf-c-table__expandable-row-content {
        padding: 0; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row:not(.pf-m-expanded) {
        display: none;
        visibility: hidden; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__expandable-row .pf-c-table__expandable-row-content {
        padding-right: var(--pf-c-table__expandable-row-content--responsive--PaddingRight);
        padding-left: var(--pf-c-table__expandable-row-content--responsive--PaddingLeft); }
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__toggle,
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__check,
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__favorite,
    .pf-m-grid-2xl.pf-c-table tr:not(.pf-c-table__expandable-row) .pf-c-table__action {
      width: auto;
      padding: 0; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__toggle {
      grid-row-start: 20;
      grid-column: -1;
      justify-self: end;
      padding-right: 0; }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__toggle::after {
        content: none; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__button {
      --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-grid--cell--PaddingTop);
      --pf-c-table--cell--PaddingRight: var(--pf-c-table--m-grid--cell--PaddingRight);
      --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-grid--cell--PaddingBottom);
      --pf-c-table--cell--PaddingLeft: var(--pf-c-table--m-grid--cell--PaddingLeft); }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__check,
    .pf-m-grid-2xl.pf-c-table .pf-c-table__favorite,
    .pf-m-grid-2xl.pf-c-table .pf-c-table__action {
      grid-row-start: 1;
      grid-column-start: 2; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__check {
      margin-top: var(--pf-c-table__check--responsive--MarginTop);
      margin-left: var(--pf-c-table__check--responsive--MarginLeft); }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite {
        margin-left: var(--pf-c-table--m-grid__check--favorite--MarginLeft); }
        .pf-m-grid-2xl.pf-c-table .pf-c-table__check ~ .pf-c-table__favorite ~ .pf-c-table__action {
          margin-left: var(--pf-c-table--m-grid__check--favorite--action--MarginLeft); }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__check ~ .pf-c-table__action {
        margin-left: var(--pf-c-table__action--responsive--MarginLeft); }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__favorite {
      margin-top: var(--pf-c-table--m-grid__favorite--MarginTop); }
      .pf-m-grid-2xl.pf-c-table .pf-c-table__favorite ~ .pf-c-table__action {
        margin-left: var(--pf-c-table--m-grid__favorite--action--MarginLeft); }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__action {
      margin-top: var(--pf-c-table--m-grid__action--MarginTop);
      text-align: right; } }
    @media screen and (max-width: 1450px) and (max-width: 576px) {
      .pf-m-grid-2xl.pf-c-table .pf-c-table__action {
        grid-row-start: 1;
        grid-column-start: 2;
        margin-left: 0; } }

@media screen and (max-width: 1450px) {
    .pf-m-grid-2xl.pf-c-table .pf-c-table__inline-edit-action {
      grid-column: 2;
      grid-row: 2; }
    .pf-m-grid-2xl.pf-c-table .pf-c-table__toggle-icon {
      transition: var(--pf-c-table__toggle__icon--Transition); }
      .pf-c-button.pf-m-expanded > .pf-m-grid-2xl.pf-c-table .pf-c-table__toggle-icon {
        transform: rotate(var(--pf-c-table__toggle--m-expanded__icon--Rotate)); }
    .pf-m-grid-2xl.pf-c-table .pf-m-nowrap {
      --pf-c-table--cell--Overflow: auto; }
    .pf-m-grid-2xl.pf-c-table .pf-m-fit-content {
      width: auto;
      white-space: normal; }
    .pf-m-grid-2xl.pf-c-table .pf-m-truncate {
      --pf-c-table--cell--MaxWidth: 100%; }
    .pf-m-grid-2xl.pf-c-table [class*="pf-m-width"] {
      --pf-c-table--cell--Width: auto; } }
